@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap);
.SignUp_sign_up__1Wyp2 {
  display: block;
  /* border: 1px solid rgba(189, 189, 189, 1); */
  height: 716px;
  width: auto;
}

@media screen and (max-width: 400px) {
  .SignUp_sign_up__1Wyp2 {
    height: 816px;
  }
}

.SignUp_back_button__cuPNo {
  margin: 20px;
}

.SignUp_btn__MNGAI {
  background-color: white;
  border: none;
}

.SignUp_sign_up_hint__1Z5ze {
  position: relative;
  font-family: "Montserrat";
  top: 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
}

.SignUp_sign_up_options__2AMMl {
  position: relative;
  top: 15%;
}

.SignUp_union_svg__1FyAP {
  display: block;
  margin: auto;
}

.SignUp_sign_up_hint_text__1cJiz {
  display: block;
  position: relative;
  top: 20px;
  width: 300px;
  margin: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #000000;
}

.SignUp_sign_in__1DH4B {
  display: block;
  padding-top: 20px;
}

.SignUp_sign_in__1DH4B .SignUp_btn__MNGAI {
  display: block;
  border: 2px solid #7c10a7;
  height: 45px;
  width: 47%;
  margin: auto;
  border-radius: 5px;
  text-align: center;
  background: white;
  cursor: pointer;
  border-radius: 5px;
}

@media screen and (max-width: 550px) {
  .SignUp_sign_in__1DH4B .SignUp_btn__MNGAI {
    width: 50%;
  }
}

.SignUp_sign_in__1DH4B .SignUp_btn__MNGAI p {
  display: block;
  margin: auto;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #7c10a7;
}

.EmailSignUp_email_sign_up__27-IS {
  position: relative;
  top: 150%;
}

.EmailSignUp_btn__3b5lD {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  text-align: center;
  background: #7c10a7;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}

@media screen and (max-width: 480px) {
  .EmailSignUp_btn__3b5lD {
    width: 80%;
  }
}

.EmailSignUp_btn__3b5lD p {
  display: block;
  margin: auto;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
.GoogleSignUp_google_sign_up__QhxAp {
  display: block;
  position: relative;
  top: 200%;
  border: 2px solid rgba(0, 0, 0, 1);
  margin: auto;
  width: 295px;
  height: 46px;
  border-radius: 5px;
}

@media screen and (max-width: 480px) {
  .GoogleSignUp_google_sign_up__QhxAp {
    border: 1px solid;
    width: 80%;
  }
}


.GoogleSignUp_google__3k3vM {
  display: block;
  margin: auto;
  width: 250px;
  height: 42px;
}

@media screen and (max-width: 2224px) {
  .GoogleSignUp_google__3k3vM {
    width: 100%;
  }
}

.GoogleSignUp_google_text__3H1P5 {
  display: block;
  margin: auto;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  text-align: center;
}


.GoogleSignUp_google_sign_up__QhxAp {
  display: flex;
  align-items: center;
}

.GoogleSignUp_google_button__3i7w5 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  width: 295px;
  margin-right: 0.9rem;
  border: 1px solid #000;
  border-radius: 0.25rem;
}

.GoogleSignUp_google_icon__Xkxks {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.9rem;
}

.LogInMe_sign_in__2tHcM {
  display: block;
  position: relative;
  top: 115px;
}

.LogInMe_btn__dzZ1D {
  display: block;
  border: 2px solid #7c10a7;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  text-align: center;
  background: white;
  cursor: pointer;
  border-radius: 5px;
}
@media screen and (max-width: 480px) {
  .LogInMe_btn__dzZ1D {
    width: 80%;
  }
}


.LogInMe_btn__dzZ1D p {
  display: block;
  margin: auto;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #7c10a7;
}
.Cateogory_cateogory_screen__2VSJR {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}
.Cateogory_back_button__BwiNF {
  padding-top: 30px;
  padding-left: 20px;
}
.Cateogory_btn__6_Ob7 {
  background-color: white;
  border: none;
}

.Cateogory_cateogory_screen_content__e3Kju {
  display: block;
  height: 29px;
  width: 295px;
  margin: auto;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
}

.Cateogory_cateogory_screen_title__2YLNd {
  display: block;
  height: 29px;
  width: 295px;
  margin: auto;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
  padding-top: 34px;
}

.Cateogory_next_button__mlndj {
  text-align: center;
  padding-top: 8%;
  padding-left: 3%;
}
.Cateogory_next_continue__1Zb1O {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}
.Cateogory_next_continue__1Zb1O p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}

.LogoIcon_logo_icon__1qdV7 {
  display: block;
  margin: auto;
}

.ListCategories_list_icons__jkxnb {
  padding-top: 50px;
  display: block;
}
.ListCategories_scrolling_box__2G54c {
  display: block;
  margin: auto;
  width: 340px;
  height: 420px;
  overflow-y: scroll;
  
  text-align: center;
}


#ListCategories_style__1Ni5O::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#ListCategories_style__1Ni5O::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#ListCategories_style__1Ni5O::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #7c10a7;
}
.ListCategories_outer_selected_box__3Edse {
  display: block;
  border-radius: 5px;
  height: 80px;
  width: 97%;
  margin: auto;
  border: 2px solid;
  border-image: linear-gradient(#4cc9f0, #4895ef, #560bad, #b5179e, #f72585) 27;
}

.ListCategories_outer_box__2AHxp:hover {
  border-radius: 5px;
  border: 2px solid ;
   border-image: linear-gradient(#4cc9f0, #4895ef, #560bad, #b5179e, #f72585) 27;
  cursor: pointer;
}
section {
  align-items: center;
  justify-content: center;
  height: 100%;
}
.ListCategories_icon_container__jnHEh {
  display: inline-flex;
}

.ListCategories_outer_box__2AHxp {
  display: block;
  cursor: pointer;
  border-radius: 5px;
  height: 80px;
  width: 70px;
  border: 1px solid #bdbdbd;
  margin: 3px;
}
.ListCategories_icons__1aw58 {
  display: block;
  margin: auto;
  padding-top: 20px;
  height: 20px;
  cursor: pointer;
  width: 36px;
  font-family: Fugaz One;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
}
.ListCategories_icon_title__3wQAd {
  display: block;
  margin: auto;
  cursor: pointer;
  padding-top: 30px;
  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  height: 12px;
  width: 70px;
}

.WelcomeUser_welcome_user_screen__3x1Rj {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}
.WelcomeUser_back_button__GRkF- {
  padding-top: 30px;
  padding-left: 20px;
}
.WelcomeUser_btn__2AxLN {
  background-color: white;
  border: none;
}
.WelcomeUser_name__2L71- {
  color: #7c10a7;
}
.WelcomeUser_user_icon__2a5z_ {
  text-align: center;
  padding: 10px;
  padding-top: 25%;
}
.WelcomeUser_content__3heeA {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  padding-bottom: 20px;
}
.WelcomeUser_certified_title__3NHeX {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
}
.WelcomeUser_continue_button__zFYNl {
  text-align: center;
  position: relative;
  top: 210px;


}
@media (min-width: 1400px) and (max-width: 2767px) {
  .WelcomeUser_continue_button__zFYNl {
    position: relative;
    top: 210px;
  }
}
@media screen and (max-width: 680px) {
  .WelcomeUser_continue_button__zFYNl {
    padding-top: 5%;
  }
}
.WelcomeUser_button_continue__2kvCS {
  display: block;
  height: 45px;
  padding-left: 15%;
  padding-right: 15%;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}
.WelcomeUser_button_continue__2kvCS p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}


.app-wrapper > .row > [class*='col']{
    margin: 0 auto;
}
.Questions_question_screen__j9hTW {
  display: block;
  width: auto;
  height: 716px;
  /* border: 1px solid #bdbdbd; */
}

@media screen and (max-width: 668px) {
  .Questions_question_screen__j9hTW {
    height: 100%;
  }
}

.Questions_question_heading__33UvN {
  margin: 15px;
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  /* line-height: 29px; */
}

.Questions_image_grid_wrapper__1tG_z {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px;
}

.Questions_Logout__25T7D {
  position: relative;
  /* top: -15px; */
}

.Questions_post_remain_timer__3Ls9q {
  display: flex;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  margin-top: 20px;
  max-width: 190px;
}



@media screen and (max-width: 468px) {
  .Questions_post_remain_timer__3Ls9q {
    display: flex;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    margin-top: 20px;
    max-width: 190px;
    margin-left:-20px
  }
}

.Questions_live_timer__1HPtn {
  padding-left: 5px;
  padding-top: 5px;
  
}

@media screen and (max-width: 468px) {
  .Questions_live_timer__1HPtn {
   width: 0px;
   padding-left: 0px;
  }
}
.Questions_scrollbar_question_screen__d2fKI {
  max-height: 716px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 !important;
  margin:auto
}

@media only screen and (max-width: 767px) {
  .Questions_width-web__1xzK4 {
    max-height: 716px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 !important;
  }
}


.Questions_scrollbar_question_screen__d2fKI::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.Questions_scrollbar_question_screen__d2fKI::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.Questions_scrollbar_question_screen__d2fKI::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #7c10a7;
}
#image6 img,
#image3 img {
  height: 162px;
}
div#image3 {
  display: block;
}
#image5 img {
  height: 165px;
}

#image2 img {
  height: 510px;
}

.enable-img-overlay:after {
  content: "";
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

.bgImg {
  max-width: 100%;
  width: 100%;
  display: inline-block;
  position: relative;
  height: 260px;
  object-fit: cover;
}

.img {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(50% - 4px);
  position: relative;
  margin: 2px;
  overflow: hidden;
}

.img:first-child:nth-last-child(3) ~ .img:nth-child(1),
.img:first-child:nth-last-child(3) ~ .img:nth-child(2),
.img:first-child:nth-last-child(3) ~ .img:nth-child(3) {
  /* flex: 0 0 100%; */
  flex: 100% 1;
  height: 162px;
}

.img:first-child:nth-last-child(3) ~ .img:nth-child(1) img {
  height: 162px;
}

/* .img:first-child:nth-last-child(3) ~ .img:nth-child(1) {
  height: 162px;
} */

.paraGraph {
  margin-top: 0;
  margin-bottom: 0rem;
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 0;
  color: #fff;
  background: #00000080;
  padding: 20px;
}

.Clickimg {
  box-sizing: border-box;
  position: absolute;
  width: 60px;
  height: 60px;
  display: inline-block;
  top: 23%;
  left: 40%;
  -webkit-transform: traslate(-50%, -50%);
          transform: traslate(-50%, -50%);
  border-radius: 50px;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
  width: 100%;
}

@media screen and (max-width: 768px)  { 
  .Toastify__toast-theme--colored.Toastify__toast--success  {
    width: 100%;

    
  }
}


.QuestionCommentAndVote_question_heading_content__19VRp {
  display: block;
  font-family: "Montserrat";
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  margin: 10px;
}

.QuestionCommentAndVote_comment_and_vote__3Nptx {
  margin: 10px;
}

.QuestionCommentAndVote_comment__1keGd {
  cursor: pointer;
}

.QuestionCommentAndVote_count__35Og7 {
  margin: 7px;
}

.QuestionCommentAndVote_count_section__3Hu6U {
  display: flex;
  /* width: 15%; */
  justify-content: center;
}

@media screen and (min-device-width: 768px) and (max-device-width: 1200px) {
  .QuestionCommentAndVote_count_section__3Hu6U {
    width: 20%;
  }
}

@media screen and (max-width: 400px) {
  .QuestionCommentAndVote_count_section__3Hu6U {
    width: 20%;
  }
}

.QuestionCommentAndVote_comments__3HxNT {
  text-align: center;
  cursor: pointer;
}

.QuestionCommentAndVote_votes__2aJSW {
  text-align: center;
  cursor: pointer;
}

.QuestionCommentAndVote_vote__3PcUH {
  cursor: pointer;
}

.QuestionCommentAndVote_read_or_hide__1RPfe {
  cursor: pointer;
}

.QuestionCommentAndVote_text__29mTU {
  position: relative;
  display: block;
  margin: 5px;
  font-family: "Montserrat";
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
}

.QuestionCommentAndVote_heading__XNIaa {
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  width: 98%;
}

.QuestionCommentAndVote_questionCategory__3ZA4r {
  margin: 10px;
  margin-top: -7px;
  margin-bottom: -4px;
}

.MultipleOptionsAndYesOrNoQuestion_question_heading__3-Ctg {
  margin: 15px;
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  width: 90%;
}

.MultipleOptionsAndYesOrNoQuestion_question_img__3hzMb {
  height: 480px;
  margin: auto;
  background-repeat: no-repeat;
}

.MultipleOptionsAndYesOrNoQuestion_multiple_type_question_image__PRuWw {
  height: 480px;
  background: linear-gradient(
    254.59deg,
    #4cc9f0 -3.55%,
    #4895ef 14.74%,
    #560bad 44.39%,
    #b5179e 74.54%,
    #f72585 91.34%
  );

  margin: auto;
}

@media screen and (max-width: 1024px) {
  .MultipleOptionsAndYesOrNoQuestion_question_img__3hzMb {
    background-position: center;
  }
}

.MultipleOptionsAndYesOrNoQuestion_question__2L1dT {
  background-color: transparent;
  margin: auto;
  position: relative;
  top: 28%;
}

@media screen and (max-width: 624px) {
  .MultipleOptionsAndYesOrNoQuestion_question__2L1dT {
    width: auto;
  }
}

.MultipleOptionsAndYesOrNoQuestion_button__30HWT {
  width: 295px;
  display: flex;
  margin: 10px auto;
  border-radius: 25px;
  border: 2px solid black;
  background: white;
}
@media screen and (max-width: 424px) {
  .MultipleOptionsAndYesOrNoQuestion_button__30HWT {
    width: 75%;
  }
}

.MultipleOptionsAndYesOrNoQuestion_multiple_choice_alignment__1NGua {
  margin: 3px;
  padding: 2px;
  width: 90%;
  text-align: left;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
}

.MultipleOptionsAndYesOrNoQuestion_button__30HWT:hover {
  border: 2px solid skyblue;
}

.MultipleOptionsAndYesOrNoQuestion_active_option_span__3hK7h {
  border: 2px solid black;
  background-color: white;
  color: #7c10a7;
}

.MultipleOptionsAndYesOrNoQuestion_active_option__NftFP {
  color: white;
  background: linear-gradient(
    254.59deg,
    #4cc9f0 -3.55%,
    #4895ef 14.74%,
    #560bad 44.39%,
    #b5179e 74.54%,
    #f72585 91.34%
  );
}

.MultipleOptionsAndYesOrNoQuestion_sno__3xb8F {
  margin: auto;
  width: 21px;
  border-radius: 49px;
  border: 2px solid;
  padding: 0px 4px 0px 4px;
}

.MultipleOptionsAndYesOrNoQuestion_index__1quBB {
  font-size: 11px;
  font-weight: 700;
}

.MultipleOptionsAndYesOrNoQuestion_yes_no_question__3vnE4 {
  border-top: 11px solid white;
  border-bottom: 10px solid white;
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
  top: 43%;
  height: 70px;
  width: 34%;
}

.MultipleOptionsAndYesOrNoQuestion_yes_button__3DzkX {
  font-size: 28px;
  width: 131px;
  padding: 0px;
  height: 70px;
  background-color: white;
  border: none;
  margin: 1px;
  margin-top: -11px;
}

.MultipleOptionsAndYesOrNoQuestion_yes_or_no_text__vi2Yr {
  width: auto;
  padding-top: 13px;
  font-family: "Montserrat";
  text-transform: uppercase;

  font-size: 30px;
  font-weight: 700;
  margin-top: -11px;
}

.MultipleOptionsAndYesOrNoQuestion_image_grid_wrapper__2BpRB {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px;
}

.MultipleOptionsAndYesOrNoQuestion_img__32p57:after {
  content: "";
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

video {
  width: 100%;
  height: 499px;
  object-fit: cover;
}

.MultipleOptionsAndYesOrNoQuestion_content__OGfyP {
  position: absolute;
  width: 750px;
  height: 480px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.MultipleOptionsAndYesOrNoQuestion_overlay__1BFyy {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.MultipleOptionsAndYesOrNoQuestion_yes_no_video_question__zOTa5 {
  border-top: 11px solid white;
  border-bottom: 10px solid white;
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
  top: 12%;
  height: 70px;
  width: 34%;
}

.MultipleImageWithTextQuestion_bgImg__2yOeo {
  max-width: 100%;
  width: 100%;
  display: inline-block;
  position: relative;
  height: 240px;
  object-fit: cover;
}

.MultipleImageWithTextQuestion_img__2S3mO {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(50% - 4px);
  position: relative;
  margin: 2px;
  overflow: hidden;
}


.MultipleImageWithTextQuestion_paraGraph__QsDYH {
  margin-top: 0;
  margin-bottom: 0rem;
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 0;
  color: #fff;
  background: #00000080;
  padding: 20px;
} 

.MultipleImageWithTextQuestion_Clickimg__3fGjq {
  box-sizing: border-box;
  position: absolute;
  width: 60px !important;
  height: 60px !important;
  top:20px;
  left: 0;
  right: 0;
  margin-left:auto;
  margin-right: auto;
  border-radius: 50px;
}

.PostCloseTime_modal__1RuFn {
  display: block;
  width: auto;
  height: 716px;
}

.PostCloseTime_modal_Text__1poAy {
  /* background: linear-gradient(
    254.59deg,
    #4cc9f0 -3.55%,
    #4895ef 14.74%,
    #560bad 44.39%,
    #b5179e 74.54%,
    #f72585 91.34%
  ); */
  text-align: center;
  color: black;
  font-weight: 600;
  font-family: "Montserrat";
}

.PostCloseTime_time_background__2Muaf {
  padding: 4px;
  color: black;
  width: 210px;
  margin: auto;
}

.PostCloseTime_remain_time__2TSMc {
  font-size: 28px;
  color: #7c10a7;
  margin: auto;
}

.MessageForAnnonymusUser_remain_time__1d9xj {
  font-size: 28px;
  color: #7c10a7;
  margin: auto;
}

.MessageForAnnonymusUser_popUp_signUp_btn__33xkv {
  background-color: #7c10a7;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  width: 300px;
  padding: 8px;
  margin: 30px 0px 10px 0px;
  font-family: "Montserrat";
}

@media (max-width: 768px) {
  .MessageForAnnonymusUser_popUp_signUp_btn__33xkv {
    width: 100%;
  }
}

.MessageForAnnonymusUser_button_container__-keqC {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}
.MessageForAnnonymusUser_popUp_signUp_btn__33xkv:hover {
  background-color: #7c10a7;
  border-color: #7c10a7;
}

.MessageForAnnonymusUser_popUp_login_btn__rYcoU {
  border-radius: 5px;
  border: none;
  background-color: white;
  color: #7c10a7;
  border: 1px solid #7c10a7;
  font-size: 20px;
  font-family: "Montserrat";
  width: 300px;
  padding: 8px;
}
@media (max-width: 768px) {
  .MessageForAnnonymusUser_popUp_login_btn__rYcoU {
    width: 100%;
  }
}

.MessageForAnnonymusUser_download_btn__34QR4 {
  width: 100%;
}

@media (max-width: 768px) {
  .MessageForAnnonymusUser_download_btn__34QR4 {
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.MessageForAnnonymusUser_popUp_login_btn__rYcoU:hover {
  background-color: #7c10a7;
  color: white;
  border-color: #7c10a7;
}



.MessageForAnnonymusUser_heading_logo__3Tvq5{
  padding-top: 1px;
 text-align: center;
 justify-content: center;
 margin: auto;
 width: 100%;
}
.MessageForAnnonymusUser_footer_title__14hmw {
  font-family: "Montserrat";
  margin-top:20px;
  margin-bottom:0px;
  font-size: 14px;
  text-align: center;
  color: black;
  font-weight: 600;
}
@media (max-width: 768px) {
  .MessageForAnnonymusUser_footer_title__14hmw {
    margin: 20px auto;
  }
}

.MessageForAnnonymusUser_download_btn__34QR4{
  margin-top: 12px;
  justify-content: center;
  text-align: center;
}
.MessageForAnnonymusUser_logoContainer__3PdvJ {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
}
.MessageForAnnonymusUser_logo__1hm0i {
  width: 140px;
  /* height: 120px; */
}
.MessageForAnnonymusUser_android_logo__2mF65 {
  width: 150px;
}
.MessageForAnnonymusUser_ios_logo__3R4uM {
  width: 120px;
  height: auto;
}

@media (max-width: 768px) {
  .MessageForAnnonymusUser_ios_logo__3R4uM {
    margin-left: 0px;
    height: auto;
  }
}
.MessageForAnnonymusUser_dialogText__3pq-_ {
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 10px;
  font-family: "Montserrat";
  color: black;
  font-weight: 600;
  text-align: center;
}

.MessageForAnnonymusUser_subtitle__1AJ4c {
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
}

.MessageForAnnonymusUser_close_button__UWLHe:hover {
  background-color: #7c10a7;
  color: white;
  border-color: #7c10a7;
}

.MessageForAnnonymusUser_close_button__UWLHe {
  border-radius: 5px;
  border: none;
  background-color: white;
  color: #7c10a7;
  border: 1px solid #7c10a7;
  font-size: 20px;
  font-family: "Montserrat";
  width: 300px;
  padding: 8px;
}
@media (max-width: 768px) {
  .MessageForAnnonymusUser_close_button__UWLHe {
    width: 100%;
  }
}
.bg-custom-primary {
    background-color: #7c10a7 !important;
  }
  
  .border-custom-primary {
    border-color: #7c10a7;
  }
  
  .bg-custom-white {
    background-color: white;
  }
.MessageOnVoteAnnonymusUser_remain_time__W7TPi {
  font-size: 28px;
  color: #7c10a7;
  margin: auto;
}

.MessageOnVoteAnnonymusUser_popUp_signUp_btn__3uGJW {
  background-color: #7c10a7;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  width: 300px;
  padding: 8px;
  margin: 30px 0px 10px 0px;
  font-family: "Montserrat";
}

@media (max-width: 768px) {
  .MessageOnVoteAnnonymusUser_popUp_signUp_btn__3uGJW {
    width: 100%;
  }
}

.MessageOnVoteAnnonymusUser_button_container__3oepy {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}
.MessageOnVoteAnnonymusUser_popUp_signUp_btn__3uGJW:hover {
  background-color: #7c10a7;
  border-color: #7c10a7;
}

.MessageOnVoteAnnonymusUser_popUp_login_btn__j6Oqp {
  border-radius: 5px;
  border: none;
  background-color: white;
  color: #7c10a7;
  border: 1px solid #7c10a7;
  font-size: 20px;
  font-family: "Montserrat";
  width: 300px;
  padding: 8px;
}
@media (max-width: 768px) {
  .MessageOnVoteAnnonymusUser_popUp_login_btn__j6Oqp {
    width: 100%;
  }
}

.MessageOnVoteAnnonymusUser_download_btn__1ETXf {
  width: 100%;
}

@media (max-width: 768px) {
  .MessageOnVoteAnnonymusUser_download_btn__1ETXf {
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.MessageOnVoteAnnonymusUser_popUp_login_btn__j6Oqp:hover {
  background-color: #7c10a7;
  color: white;
  border-color: #7c10a7;
}



.MessageOnVoteAnnonymusUser_heading_logo__3xN-a{
  padding-top: 1px;
 text-align: center;
 justify-content: center;
 margin: auto;
 width: 100%;
}
.MessageOnVoteAnnonymusUser_footer_title__W38xe {
  font-family: "Montserrat";
  margin-top:20px;
  margin-bottom:0px;
  font-size: 14px;
  text-align: center;
  color: black;
  font-weight: 600;
}
@media (max-width: 768px) {
  .MessageOnVoteAnnonymusUser_footer_title__W38xe {
    margin: 20px auto;
  }
}

.MessageOnVoteAnnonymusUser_download_btn__1ETXf{
  margin-top: 12px;
  justify-content: center;
  text-align: center;
}
.MessageOnVoteAnnonymusUser_logoContainer__3V6UG {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
}
.MessageOnVoteAnnonymusUser_logo__2B83n {
  width: 140px;
  /* height: 120px; */
}
.MessageOnVoteAnnonymusUser_android_logo__2XvIN {
  width: 150px;
}
.MessageOnVoteAnnonymusUser_ios_logo__EuRUn {
  width: 120px;
  height: auto;
}

@media (max-width: 768px) {
  .MessageOnVoteAnnonymusUser_ios_logo__EuRUn {
    margin-left: 0px;
    height: auto;
  }
}
.MessageOnVoteAnnonymusUser_dialogText__3rZym {
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 10px;
  font-family: "Montserrat";
  color: black;
  font-weight: 600;
  text-align: center;
}

.MessageOnVoteAnnonymusUser_subtitle__1Nrc5 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
}

.MessageOnVoteAnnonymusUser_close_button__2_jBv:hover {
  background-color: #7c10a7;
  color: white;
  border-color: #7c10a7;
}

.MessageOnVoteAnnonymusUser_close_button__2_jBv {
  border-radius: 5px;
  border: none;
  background-color: white;
  color: #7c10a7;
  border: 1px solid #7c10a7;
  font-size: 20px;
  font-family: "Montserrat";
  width: 300px;
  padding: 8px;
}
@media (max-width: 768px) {
  .MessageOnVoteAnnonymusUser_close_button__2_jBv {
    width: 100%;
  }
}
.bg-custom-primary {
    background-color: #7c10a7 !important;
  }
  
  .border-custom-primary {
    border-color: #7c10a7;
  }
  
  .bg-custom-white {
    background-color: white;
  }
.SignInMessage_modal__3HAv9 {
    display: block;
    width: auto;
    height: 716px;
}

.SignInMessage_modal_Text__31GRP {
    max-width: 420px;
    margin: auto;
    text-align: center;
    font-size: 20px;
    color: black;
    font-weight: 600;
    font-family: "Montserrat";
}
.PrivacyPolicy_button_continue__2KxMd:disabled {
  background-color: #b771d3;
}

.PrivacyPolicy_privacy_policy_screen__ShvIz {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}

.PrivacyPolicy_back_button__3OD0u {
  padding-top: 30px;
  padding-left: 20px;
}

.PrivacyPolicy_btn__33E07 {
  background-color: white;
  border: none;
}

.PrivacyPolicy_button_continue__2KxMd:disabled {
  background-color: #b771d3;
}

.PrivacyPolicy_privacy_policy_icon__lREbT {
  display: block;
  margin: auto;
  text-align: center;
  padding-top: 40px;
}

.PrivacyPolicy_title__37BSr {
  display: block;
  margin: auto;
  height: 58px;
  width: 49%;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  padding-top: 15px;
}

@media screen and (max-width: 680px) {
  .PrivacyPolicy_title__37BSr {
    width: auto;
  }
}

.PrivacyPolicy_contents__3EjwL {
  display: block;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  margin: auto;
  height: 124px;
  width: 292px;
  padding-top: 1%;
}

@media screen and (max-width: 680px) {
  .PrivacyPolicy_contents__3EjwL {
    width: auto;
  }
}

.PrivacyPolicy_privacy_policy_btn__maduM {
  display: block;
  margin: auto;
  padding-top: 250px;
}

@media screen and (max-width: 1080px) {
  .PrivacyPolicy_privacy_policy_btn__maduM {
    padding-top: 250px;
    padding-left: 0px;
  }
}

.PrivacyPolicy_privacy_policy_button__W7wu1 {
  display: block;
  margin: auto;
  height: 45px;
  width: 292px;
  border-radius: 5px;
  border: 2px solid rgba(124, 16, 167, 1);
  background: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 680px) {
  .PrivacyPolicy_privacy_policy_button__W7wu1 {
    margin: auto;
  }
}

.PrivacyPolicy_privacy_policy_button_title__2Jh1z {
  margin: auto;
  font-family: "Montserrat";
  width: 295px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #7c10a7;
}

@media screen and (max-width: 880px) {
  .PrivacyPolicy_privacy_policy_button_title__2Jh1z {
    width: auto;
  }
}

.PrivacyPolicy_continue_button__9doP0 {
  text-align: center;
  position: relative;
  top: 18%;
}

@media screen and (max-width: 680px) {
  .PrivacyPolicy_continue_button__9doP0 {
    padding-top: 5%;
  }
}

.PrivacyPolicy_button_continue__2KxMd {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 380px) {

  .PrivacyPolicy_button_continue__2KxMd {
    width: 70%;
  }

}

.PrivacyPolicy_button_continue__2KxMd p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}
.privacy_policy_sub_title {
  display: flex;
  margin: auto;
}

.privacy_policy_content {
  padding-left: 49px;
  width: 296px;
  margin-top: -37px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  height: 45px;
}

.privacy-section {
  display: inline-block;
  margin-left: 39px;

}
@media only screen and (max-width: 500px) {
  .privacy-section {
    display: inline-block;
    margin-left: 65px;
  }
}

.SignUpPassword_Password__1Nsc2 {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}
.SignUpPassword_back_button__3_4_P {
  padding-top: 30px;
  padding-left: 20px;
}
.SignUpPassword_btn__96Ose {
  background-color: white;
  border: none;
}
.SignUpPassword_SignUpSection__DS-fY {
  padding-top: 25px;
}
.SignUpPassword_PasswordSvg__1mHBW {
  display: block;
  margin: auto;
  width: 78px;
  height: 76px;
}
.SignUpPassword_SignUpSection__DS-fY p {
  display: block;
  padding-top: 15px;
  width: 295px;
  height: 58px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: hsl(0, 0%, 0%);
  margin: auto;
}
@media screen and (max-width: 480px) {
  .SignUpPassword_SignUpSection__DS-fY p {
    width: auto;
  }
}
.SignUpPassword_InputField_input_placeholder__2gWIN {
  padding-top: 10px;
}
.SignUpPassword_InputField_confirm_password__1IIIS {
  padding-top: 50px;
}
.SignUpPassword_input_placeholder__1SQF8 {
  display: block;
  height: 45px;
  width: 295PX;
  margin: auto;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 0 40px 0 40px;
}
@media screen and (max-width: 480px) {
  .SignUpPassword_input_placeholder__1SQF8 {
    border: 1px solid;
    width: 80%;
  }
}
.SignUpPassword_heading__3TNBb {
  display: flex;
  padding-top: 20px;
  height: 58px;
  width: 37%;
  margin: auto;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .SignUpPassword_heading__3TNBb {
    width: auto;
    justify-content: center;
  }
}
.SignUpPassword_confirm_password__q-A7Z {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 0 40px 0 40px;

}
@media screen and (max-width: 480px) {
  .SignUpPassword_confirm_password__q-A7Z {
    border: 1px solid;
    width: 80%;
  }
}
.SignUpPassword_continue_button__k_iTb {
  text-align: center;
  padding-top: 5%;
}

.SignUpPassword_button_continue__2xL8y {
  display: block;
  height: 45px;
  width: 295px;

  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 450px) {
  .SignUpPassword_button_continue__2xL8y  {
  width: 80%;

  }
}
.SignUpPassword_button_continue__2xL8y p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}

.SignUpPassword_button_continue__2xL8y:disabled {
  background-color: #b771d3;
}
.SignUpPassword_hide_show_password__3ye1f {
  position: relative;
  width: 3%;
  text-align: center;
  left: 65%;
  bottom: 33px;
}
@media screen and (max-width: 450px) {
  .SignUpPassword_hide_show_password__3ye1f  {
    left: 73%;

  }
}
.ValidationPassword_password_hint_list__3C_sW p {
  display: contents;
  font-weight: 600;
  font-size: 13px;
  font-family: "Montserrat";
  color: #7c10a7;
}
.ValidationPassword_password_hint_lis__1UNDH{
  padding: 3px;
}
.ValidationPassword_password_disable_hint_list__3JJzN p {
  display: contents;
  font-family: "Montserrat";
  font-size: 13px;
  color: #bdbdbd;
}
.ValidationPassword_ppp__2asy9{
  padding: 10px
}

.ValidationPassword_disablecheckboxSvg__1zpzC{
  position: relative;
  right: 4px;
}
.ValidationPassword_validationBox__1lyGy {
  margin-top: 5px
}
@media screen and (max-width: 800px) {
  .ValidationPassword_validationBox__1lyGy {
  }
}
.Logout_logout_btn__3i08L {
  padding-top: 30px;
  cursor: pointer;
}
@media (min-width: 580px) {
    .Logout_logout_btn__3i08L {
     padding-right: 20px;  
    }
}
.Logout_btn__1dxou {
  border: none;
  background: transparent;
}

/* .SignUp_Birthday_Screen {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}

.back_button {
  padding-top: 30px;
  padding-left: 20px;
}

.btn {
  background-color: white;
  border: none;
}

.button_continue:disabled {
  background-color: #b771d3;
}

.SignUpSection {
  text-align: center;
  position: relative;
  top: 57%;
}

.Calender {
  display: block;
  margin: auto;
  padding: auto;
}

.SignUpSection p {
  display: block;
  width: auto;
  margin: auto;
  padding-top: 25px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}

.SignUpSection span {
  display: block;
  margin: auto;
  width: auto;
  padding-top: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.InputField {
  padding-top: 7%;
}

.username {
  margin: auto;
  text-align: center;
  justify-content: center;
  height: 45px;
}

.input_placeholder {
  display: block;
  margin: auto;
  height: 45px;
  width: 295px;
  border: 1px solid;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

@media screen and (max-width: 380px) {
  .input_placeholder {
    width: 80%;
  }
}

.continue_button {
  position: relative;
  top:285px;
}

@media screen and (max-width: 680px) {
  .continue_button {
    position: relative;
    top: 40%;
  }
}

.button_continue {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 380px) {
  .button_continue {
    width: 80%;
  }
}

.button_continue p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}

 */


 .SignUpBirthday_SignUp_Birthday_Screen__kwOXy {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}

.SignUpBirthday_back_button__HsTOJ {
  padding-top: 30px;
  padding-left: 20px;
}

.SignUpBirthday_btn__bWfYc {
  background-color: white;
  border: none;
}

.SignUpBirthday_button_continue__23L5E:disabled {
  background-color: #b771d3;
}

.SignUpBirthday_SignUpSection__xh8lq {
  display: block;
  text-align: center;
  padding-top: 190px;
}
.SignUpBirthday_SignUpSection__xh8lq p {
  display: block;
  width: auto;
  margin: auto;
  padding-top: 25px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}
.SignUpBirthday_Calender__3U08M {
  display: block;
  margin: auto;
  padding: auto;
}

.SignUpBirthday_SignUpSection__xh8lq span {
  display: block;
  margin: auto;
  width: auto;
  padding-top: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.SignUpBirthday_title__32xpe {
  display: block;
  margin: auto;
  text-align: center;
  padding-top: 20px;
  width: 43%;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}

.SignUpBirthday_username__ljPF7 {
  /* display: flex; */
  margin: auto;
  text-align: center;
  justify-content: center;
  height: 45px;
}

.SignUpBirthday_input_placeholder__3Y4jr {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  width: 295px;
  margin-left: 12px;
}

@media screen and (max-width: 470px) {
  .SignUpBirthday_input_placeholder__3Y4jr {
    border: 1px solid;
    width: 70%;

  }
}

.SignUpBirthday_input_field__2ud7a {
  padding-top: 30px;
}


.SignUpBirthday_continue_button__KPxQX {
  text-align: center;
  position: relative;
  top: 20%;
}


.SignUpBirthday_button_continue__23L5E {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}

.SignUpBirthday_button_continue__23L5E p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}
.ErrorMessage_message__2zhSu {
  padding-top: 20px;
  padding-left: 15px;
}

.ErrorMessage_error_message__8T8hm {
  border: 2px solid red;
  width: 295px;
  margin: auto;
  height: 45px;
  border-radius: 5px;
}

@media screen and (max-width: 470px) {
  .ErrorMessage_error_message__8T8hm {
    line-height: 10px;
    width: 74%;
  }
}

.ErrorMessage_name_error__2bmxg {
  margin: auto;
  color: red;
  width: 275px;
  line-height: 13px;

  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  padding-top: 6px;
}

@media screen and (max-width: 470px) {
  .ErrorMessage_name_error__2bmxg {
    line-height: 15px;
    width: 84%;
  }
}

.SignUpUserName_pick_user_name_screen__v0XMy {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}

.SignUpUserName_back_button__7rgMT {
  padding-top: 30px;
  padding-left: 20px;
}

.SignUpUserName_btn__2K8E9 {
  background-color: white;
  border: none;
}

.SignUpUserName_button_continue__2vLQa:disabled {
  background-color: #b771d3;
}

.SignUpUserName_user_name_icon__Y2H0e {
  display: block;
  text-align: center;
  padding-top: 140px;
}

.SignUpUserName_title__OCRKZ {
  display: block;
  margin: auto;
  text-align: center;
  padding-top: 20px;
  width: 43%;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}

.SignUpUserName_username__1MX8x {
  /* display: flex; */
  margin: auto;
  text-align: center;
  justify-content: center;
  height: 45px;
}

.SignUpUserName_user_name__1we9f {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  width: 295px;
}

@media screen and (max-width: 470px) {
  .SignUpUserName_user_name__1we9f {
    border: 1px solid;
    width: 70%;

  }
}

.SignUpUserName_input_field__2jid4 {
  padding-top: 30px;
}


.SignUpUserName_continue_button__1LEEc {
  text-align: center;
  position: relative;
  top: 30%;
}


.SignUpUserName_button_continue__2vLQa {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}

.SignUpUserName_button_continue__2vLQa p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}
.UserExistMessage_check_box_icon__huVxu {
  margin-left: -50px;
  height: 20px;
  width: 5%;
}

.UserExistMessage_user_exist_error__3rOs8 {
  margin-left: -50px;
}

@media screen and (max-width: 470px) {
  .UserExistMessage_check_box_icon__huVxu {
    margin-left: -20px;
  }
}

.UserExistMessage_message__LlhNQ {
  padding-top: 20px;
  padding-left: 15px;
}

.UserExistMessage_error_message__Ew-Am {
  border: 2px solid red;
  width: 294px;
  margin: auto;
  height: 45px;
  border-radius: 5px;
}

@media screen and (max-width: 470px) {
  .UserExistMessage_error_message__Ew-Am {
    line-height: 10px;
    width: 54%;
  }
}

.UserExistMessage_name_error__14IUB {
  margin: auto;
  color: red;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  padding-top: 10px;
}

.SignUpTermsAndConditions_signUp_terms_and_condition__1LeMo {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}

.SignUpTermsAndConditions_back_button__2oohi {
  padding-top: 30px;
  padding-left: 20px;
}

.SignUpTermsAndConditions_btn__3bN8Q {
  background-color: white;
  border: none;
}

.SignUpTermsAndConditions_Condition_Notes__3wqHx {
  display: block;
  margin: auto;
  text-align: center;
  padding-top: 100px;
}

.SignUpTermsAndConditions_title__2ZAko {
  display: block;
  margin: auto;
  height: 58px;
  width: 295px;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  padding-top: 20px;
}

.SignUpTermsAndConditions_terms_and_conditions_btn__aADvM {
  display: block;
  margin: auto;
  padding-top: 150px;
  padding-left: 20px;
}

.SignUpTermsAndConditions_terms_and_condition_button__XjIWY {
  display: block;
  margin: auto;
  height: 45px;
  width: 295px;
  border-radius: 5px;
  border: 2px solid rgba(124, 16, 167, 1);
  background: #ffffff;
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .SignUpTermsAndConditions_terms_and_condition_button__XjIWY {
    width: 40%;
  }
}

.SignUpTermsAndConditions_terms_and_condition_button__XjIWY p{
  margin: auto;
  font-family: "Montserrat", sans-serif;
  width: auto;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #7c10a7;
  border: none;
  background-color: white;
}

@media only screen and (max-width: 1200px) {
  .SignUpTermsAndConditions_terms_and_condition_button__XjIWY p{
    font-size: 16px;
    line-height: 12px;
  }
}

.SignUpTermsAndConditions_continue_button__2Yl6v {
  text-align: center;
  position: relative;
  top: 27%;
  left: 13px;
}

.SignUpTermsAndConditions_button_continue__178EO {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 390px) {
  .SignUpTermsAndConditions_button_continue__178EO {
    width: 80%;
  }
}

.SignUpTermsAndConditions_button_continue__178EO p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}

.SignUpTermsAndConditions_button_continue__178EO:disabled {
  background-color: #b771d3;
}
.terms_and_condition_sub_title {
  display: flex;
  margin: auto;
}
.form_check {
  width: 40px;
  height: 35px;
  margin-top: 9px;
}
.terms_and_condition_content {
  padding-left: 49px;
  width: 296px;
  margin-top: -37px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  height: 45px;
}
@media only screen and (max-width: 800px) {
  .terms_and_condition_content {
    width: auto;
  }
}
.Comment_Comment__YFw5u {
  display: block;
  /* border: 1px solid rgba(189, 189, 189, 1); */
  width: auto;
  height: auto;
  border-radius: 0px;
}
.Comment_back_button__2gKPH {
  position: relative;
  top: 29%;
  left: 35%;
  cursor: pointer;
}
.Comment_btn__1HKC0 {
  background-color: white;
  border: none;
}
.Comment_button_continue__iKbgg:disabled {
  background-color: #b771d3;
}
.Comment_heading__2m5B7 {
  width: 100%;
  padding: 25px;
}
@media only screen and (max-width: 467px) {
  .Comment_heading__2m5B7 {
    width: 100%;
  padding: 25px;

  }
}
.Comment_outerBox__3FunN {
  border-top: 1px solid #bdbdbd;
}
.Comment_bottom_outerBox__1zuIy {
  border-top: 1px solid #bdbdbd;
}
.Comment_total_comment__3nQu5 {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}
.Comment_comment_field__3kJCT {
  display: flex;
  margin: 53px auto;
}
@media only screen and (max-width: 867px) {
  .Comment_comment_field__3kJCT {
    display: flex;
    margin: 30px;
  }
}
.Comment_input_placeholder__2EUGn {
  display: flex;
  width: 90%;
  height: 40px;
  border-radius: 5px;
  justify-content: center;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  border: 1px solid;

  left: 20px;
}
.Comment_post_btn__1WSX- {
  padding-top: 50px;
  text-align: center;
}
@media only screen and (max-width: 867px) {
  .Comment_post_btn__1WSX- {
    padding-top: 0px;
    margin-bottom: 20px;
  }
}
.Comment_btn_post__34RF4:disabled {
  background-color: #b771d3;
}

.Comment_btn_post__34RF4 {
  color: #ffffff;
  background: #7c10a7;
  height: 45px;
  width: 83px;
  border: none;
  border-radius: 5px;
  padding-top: 10px;
}
.Comment_btn_post__34RF4 p {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.Comment_scrollbar__2jpTC {
  overflow-y: auto; /* or overflow-y: scroll */
  overflow-x: hidden;
  height: 390px; /* set the height of the div */
}

.Comment_force-overflow__2niUV {
  min-height: 670px;
}

#Comment_wrapper__3uc4z {
  text-align: center;
  width: 500px;
  margin: auto;
}

#Comment_style__uJwYu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#Comment_style__uJwYu::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#Comment_style__uJwYu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #7c10a7;
}
.Comment_alignment__Oa2iN {
  border: "1px solid grey";
  height: 390px;
}

.CommentsAndLike_heading__1jeWf h4 {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  justify-content: center;
}

.CommentsAndLike_total_comment__2Jcdu {
  display: block;
  text-align: center;
  margin: 30px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
}

.CommentsAndLike_comment_section__4a0qg {
  margin: auto 7px;
}
@media only screen and (max-width: 467px) {
  .CommentsAndLike_comment_section__4a0qg {
    margin: auto 25px;
  }
}
.CommentsAndLike_usericon__1w2KX {
  text-align: center;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background: #c4c4c4;
}

.CommentsAndLike_user_image__1b91b {
  width: 40px;
  height: 40px;
  border-radius: 37px;
  object-fit: cover;
}

.CommentsAndLike_username__1AwjC {
  margin: auto;
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 700;
  line-height: auto;
}

.CommentsAndLike_login_username__ezm4F {
  margin: auto;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  line-height: auto;
}

.CommentsAndLike_comment__3lHch {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 400;
  line-height: auto;
  /* margin: auto; */

  color: black;
}

.CommentsAndLike_list__1fdY3 {
  margin: 5px auto;
  display: flex;
  list-style-type: none;

}

.CommentsAndLike_days__3N4mi {
  width: 33px;
  font-family: "Montserrat";
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  color: #A6A6A6;
}

.CommentsAndLike_like__2gTxz {
  width: 53px;
  font-family: "Montserrat";
  font-size: 11px;
  color: #A6A6A6;
  padding-left: 4px;
  font-weight: 600;
  line-height: 15px;
}

.CommentsAndLike_likeimg__3o0Qs {
  cursor: pointer;
}

.CommentsAndLike_reply__2RgCN {
  width: 63px;
  font-family: "Montserrat";
  font-size: 11px;
  color: #A6A6A6;
  font-weight: 600;
  line-height: 15px;
  cursor: pointer;
}

h4:before,
h4:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #7c10a7;
  margin: auto;
}

.CommentsAndLike_btn__21DPm {
  border: none;
  background-color: white;
}

.CommentsAndLike_postOwner__1_cpn {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #7c10a7;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.CommentsAndLike_postOwner__1_cpn span {
  font-family: Montserrat;
  line-height: 0.1em;

  font-size: 12px;
  font-weight: 900;
  background: #fff;
  color: #7c10a7;
  padding: 0 10px;
}
@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.radialProgressBar {
  top: 45%;
  left: 42%;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: block;
  background: #ddd;
  margin: 20px;
  position: relative;
  /* background-image: linear-gradient( 30.59deg,  #3f5efb 20.54%,  transparent 50%),
    linear-gradient(18deg, #fc46f2 10.39%, #e6e6e6 50%),
    linear-gradient(24.59deg,#4cc9f0 23.55%, #e6e6e6 50%); */

  background-image: linear-gradient(
    34.59deg,
    #4cc9f0 12.55%,
    #4895ef 12.74%,
    #560bad 30.39%,
    #b5179e 46.54%,
    #f72585 52.34%,
    transparent -1%
  );
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  transition: all 0.5s ease-in;
}
@media screen and (max-width: 750px) {
  .radialProgressBar {
    top: 45%;
    left: 38%;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: block;
    background: #ddd;
    margin: 20px;
    position: relative;
    /* background-image: linear-gradient( 30.59deg,  #3f5efb 20.54%,  transparent 50%),
      linear-gradient(18deg, #fc46f2 10.39%, #e6e6e6 50%),
      linear-gradient(24.59deg,#4cc9f0 23.55%, #e6e6e6 50%); */
  
    background-image: linear-gradient(
      34.59deg,
      #4cc9f0 12.55%,
      #4895ef 12.74%,
      #560bad 30.39%,
      #b5179e 46.54%,
      #f72585 52.34%,
      transparent -1%
    );
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition: all 0.5s ease-in;
  }
}

@media screen and (max-width: 450px) {
  .radialProgressBar {
    top: 45%;
    left: 34%;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: block;
    background: #ddd;
    margin: 20px;
    position: relative;
    /* background-image: linear-gradient( 30.59deg,  #3f5efb 20.54%,  transparent 50%),
      linear-gradient(18deg, #fc46f2 10.39%, #e6e6e6 50%),
      linear-gradient(24.59deg,#4cc9f0 23.55%, #e6e6e6 50%); */
  
    background-image: linear-gradient(
      34.59deg,
      #4cc9f0 12.55%,
      #4895ef 12.74%,
      #560bad 30.39%,
      #b5179e 46.54%,
      #f72585 52.34%,
      transparent -1%
    );
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition: all 0.5s ease-in;
  }
}
.radialProgressBar .overlay {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: #fff;
  position: absolute;
  top: 10%;
  left: 10%;
}
.load_spinner {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  width: auto;
  height: 716px;
  border-radius: 0px;
}
.spinner_text {
  position: relative;
  top: 45%;
  color: purple;
  margin: auto;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;

  text-align: center;
}

.Toastify__toast-container--top-center {
    top: 1em;
    left: 60%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.Result_question_result__G8rqS {
  display: block;
  width: auto;
  height: auto;
}
.Result_scrollbar_result_screen__xnB_5 {
  max-height: 716px;
  width: 102%;
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Result_scrollbar_result_screen__xnB_5::-webkit-scrollbar {
  background-color: #e5c8f0;
  border-radius: 5px;
  width: 10px;
}

.Result_scrollbar_result_screen__xnB_5::-webkit-scrollbar-thumb {
  background-color: #7c10a7;
  border-radius: 5px;
}


@media screen and (max-width: 425px) {
  .Result_question_result__G8rqS {
    height: auto;
  }
}

.Result_back_button__3LR6M {
  padding-top: 20px;
  padding-left: 20px;
}

.Result_btn__moK9j {
  background-color: white;
  border: none;
}

.Result_image__1FCRn {
  padding-top: 20px;
  padding-left: 30px;
}
@media screen and (max-width: 625px) {
  .Result_image__1FCRn {
    margin-left: 70px;
  }
}
@media screen and (max-width: 425px) {
  .Result_image__1FCRn {
    margin-left: 0px;
  }
}

.Result_comment__2op6J {
  cursor: pointer;
  margin: 20px auto;
}
@media screen and (max-width: 925px) {
  .Result_comment__2op6J {
    margin-left: 170px;
  }
}
@media screen and (max-width: 425px) {
  .Result_comment__2op6J {
    margin-left: 110px;
  }
}
.Result_img__3BVzH {
  border-radius: 5px;
  width: 166px;
  height: 191px;
  object-fit: cover;
}

.Result_heading__DomAm {
  padding-top: 25px;
}

.Result_heading__DomAm h3 {
  text-align: center;
  margin: auto;
  width: 85%;
  height: 100px;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
}

.Result_outerBox__3YfvA {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  height: 100%;
}

@media screen and (max-width: 425px) {
  .Result_outerBox__3YfvA {
    margin: 48px;
  }
}

.Result_vote_options__Qqhwf {
  padding-top: 35px;
  margin: auto;
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  width: 45%;
}

.Result_answer__1OSab {
  display: flex;
  margin: auto;
  justify-content: center;
  text-align: center;
  width: 45%;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
  padding-top: 30px;
  text-transform: uppercase;

}

.Result_box__2hdWJ {
  height: 10%;
}

.Result_final_answer__1Ujn9 {
  display: flex;
  margin: auto;
  justify-content: center;
  text-align: center;
  width: 45%;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  margin-top: 30px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.Result_circle__1errX {
  padding-bottom: 20px;
  float: right;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  position: relative;
}

.Result_circle__1errX p {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  font-family: Montserrat;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 30px;
}

.Result_circle__1errX::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
}

.Result_circle__1errX::after {
  content: "";
  position: absolute;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  background-color: white;
  border-radius: 50%;
  z-index: -1;
}

.Result_votes__1kP89 {
  width: 70%;
  padding-top: 20px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}

.Result_decision__3aUnm {
  display: flex;
  margin: auto;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 800;
  line-height: 15px;
  height: 15px;
  width: 294px;
  text-transform: uppercase;
}

.Result_box__2hdWJ {
  border-bottom: 1px solid #e5e5e5;
  height: auto;
}

.Result_endline__2Hlt7 {
  border-bottom: 1px solid grey;
  padding: 10px;
}
@media screen and (min-width: 480px) {
  .Login_login__wxQQI {
    display: block;
    height: 10px;
    border: 1px solid rgba(189, 189, 189, 1);
    border-radius: 0px;
  }
}

.Login_login__wxQQI {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}
.Login_back_button__16lVZ {
  padding-top: 30px;
  padding-left: 20px;
}
.Login_btn__1sBwk {
  background-color: white;
  border: none;
}
.Login_button_continue__3h25h:disabled {
  background-color: #b771d3;
}
.Login_login_section__2919K {
  padding-top: 110px;
}
.Login_Email__33Kfq {
  display: block;
  margin: auto;
  width: 76px;
  height: 80px;
}
.Login_login_section__2919K p {
  display: block;
  margin: auto;
  width: 295px;
  height: 58px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}
@media screen and (max-width: 350px) {
  .Login_login_section__2919K p {
    width: 100%;
  }
}
.Login_InputField__3n2Cb {
  display: block;
  padding-top: 25px;
}

.Login_mail_field__3eX2- {
  display: block;
  height: 45px;
  width: 295px;
  border: 1px solid #ced4da;
  margin: auto;
  border-radius: 5px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 0 20px 0 20px;
}
.Login_mail_field__3eX2-:focus {
  outline: none;
  border-color: #ced4da;
}

@media screen and (max-width: 350px) {
  .Login_mail_field__3eX2- {
    width: 70%;
  }
}
.Login_password_field__3ZwBo {
  display: block;
  height: 45px;
  width: 295px;
  border: 1px solid #ced4da;
  margin: auto;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;

  line-height: 20px;
  text-align: center;
  padding: 0 20px 0 20px;
  margin: 20px auto;
}
.Login_password_field__3ZwBo:focus {
  outline: none;
  border-color: #ced4da;
}
@media screen and (max-width: 350px) {
  .Login_password_field__3ZwBo {
    width: 70%;
  }
}

.Login_continue_button__3ETOa {
  text-align: center;
  padding-top: 25%;
}
.Login_button_continue__3h25h {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 350px) {
  .Login_button_continue__3h25h {
    width: 70%;
  }
}
.Login_button_continue__3h25h p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}

.SignIn_sign_up__26NG2 {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}

.SignIn_back_button__20vaY {
  padding-top: 30px;
  padding-left: 20px;
}
.SignIn_btn__1Kdp0 {
  background-color: white;
  border: none;
}
.SignIn_sign_up_hint__23ehE {
  display: block;
  margin: auto;
  padding-top: 5px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
}
.SignIn_sign_up_options___rxRe {
  display: block;
  margin: auto;
}
.SignIn_union_svg__2VyOu {
  display: block;
  padding-top: 200px;
  margin: auto;
}
.SignIn_sign_up_hint_text__247qP {
  display: block;
  width: 295;
  height: 55px;
  margin: auto;
  padding-top: 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}
.SignIn_google__xH4r_{
  position: relative;
  top: -70px;

}

.EmailSignIn_email_sign_up__3fGEb {
  display: block;
  padding-top: 90px;
}
.EmailSignIn_btn__10vQA {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  font-size: 20px;

  border-radius: 5px;
  text-align: center;
  background: #7c10a7;
  font-family: "Montserrat";

  color: #ffffff;

  cursor: pointer;
  border-radius: 5px;
  border: none;
}

@media screen and (max-width: 400px) {
  .EmailSignIn_btn__10vQA {
    width: 80%;

  }
}



.TermAndConditionContent_back_button__3F9oJ {
  padding-top: 30px;
  padding-left: 20px;

}

.TermAndConditionContent_back-btn__etk88 {
  background-color: white;
  border: none;
}

.TermAndConditionContent_text__vyspx h4 {
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.TermAndConditionContent_privacy__3EEdi{
 
  padding: 40px;

  border-radius: 5px;
  color: white;
  font-family: "Montserrat";
}
.TermAndConditionContent_heading__1x8X9{
  font-weight: 700;
  font-size: 1.5rem;

}
.TermAndConditionContent_bg__3gh9O{
  background:  linear-gradient(254.59deg, #4CC9F0 -3.55%, #4895EF 14.74%, #560BAD 44.39%, #B5179E 74.54%, #F72585 91.34%);
  height: 716px;
  overflow-y: scroll;

  overflow: "scroll";
}
.TermAndConditionContent_bg__3gh9O::-webkit-scrollbar {
  background-color: #e5c8f0;
  height: 397px;
  width: 10px;
  border-radius: 20px;
}

.TermAndConditionContent_bg__3gh9O::-webkit-scrollbar-thumb {
  background-color: #7c10a7;
  border-radius: 10px;
}

.TermAndConditionContent_paragraph__gvt-m{
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 400;
}
.scrollbar {
  height: 450px;
  overflow: "scroll";
  max-width: 530px;
  border-radius: 5px;
  overflow-y: scroll;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #e5c8f0;
  height: 397px;
  width: 10px;
  border-radius: 20px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #7c10a7;
  border-radius: 10px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-10 {
  margin-top: 10px;
}

.btn-border {
  color: #7c10a7;
  border: 2px solid #7c10a7;
  width: 295px;
  height: 45px;
  border-radius: 5px;
  margin-top: 10px;
}

.btn-text {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.btn-text:hover {
  background-color: white;
  color: #7c10a7;
  border: 2px solid #7c10a7;
}

.w-all {
  width: 295px;
}

.m-auto {
  margin: auto;
  width: 295px;
}

.form-width {
  width: 295px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #767676;
  outline: none;
  box-shadow: none !important;
  text-align: center;
}

.form-width:focus {
  border: 1px solid #767676;
}

.text-color {
  color: black;
}

.show-password-icon {
  position: relative;
  top: 32px;
  left: 165px;
}

.username-valid-icon {
  position: relative;
  top: 34px;
  left: 118px;
}

.show-confirm-password-icon {
  position: relative;
  top: 32px;
  left: 103px;
}

.agevalid-icon {
  position: relative;
  top: 34px;
  left: 166px;
}

.text-bold {
  font-weight: 700;
}

.montserrat-font {
  font-family: "Montserrat";
}

.label-text {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 700;
  text-align: left;
}

.link-termscondition {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  color: #7c10a7;
}

.info-text-color {
  font-family: "Montserrat";
  font-size: 12px;
  text-align: left;
  font-weight: 300;
  font-style: italic;
  color: black;
}

.placeholder-text {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #767676;
}
.custom-highlight:focus 
{
  outline:none;
  border-color: #7c10a7 !important;
}


.big-checkbox input[type="checkbox"] {
  width: 32px;
  height: 32px;
  box-shadow: none;
  border: 1px solid grey;
}

.big-checkbox label {
  display: block;
  margin-left: 20px;
}

.color-checkbox label {
  display: block;
  box-shadow: none;

  margin-left: 20px;
}

.checkbox-text {
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
}

.color-checkbox input[type="checkbox"] {
  width: 32px;
  height: 32px;
  background-color: #7c10a7;
  box-shadow: none;
  border: none;
}

.btn-enable {
  /* margin-top: 40px; */
  width: 295px;
  height: 45px;
  border: 2px solid #7c10a7 !important;
  background-color: #7c10a7 !important;
}

/* footer css start  */
.bg-linear-gradient {
  height: 100px;
  max-width: 575px;
  background: linear-gradient(254.59deg,
      #4cc9f0 -3.55%,
      #4895ef 14.74%,
      #560bad 44.39%,
      #b5179e 74.54%,
      #f72585 91.34%);
  margin: 20px auto;
}

.linear-gradient-bg {
  background: linear-gradient(254.59deg,
      #4cc9f0 -3.55%,
      #4895ef 14.74%,
      #560bad 44.39%,
      #b5179e 74.54%,
      #f72585 91.34%);
}

.footer-text {
  height: 28px;
  width: 141px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 700;
  color: white;
  text-align: end;
}

.download-text {
  width: 183px;
  font-family: "Montserrat";
  color: white;
  font-size: 19px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  padding: 25px;
}

@media only screen and (max-width: 467px) {
  .download-text {
    text-align: left;
    width: 110px;
    font-size: 18px;
  }
}
.download-links{
margin-left: 20px;
}

.download-img {
  width: 88px;
  height: 17px;
  margin-top: 10px;
  border-radius: 0px;
}

@media only screen and (max-width: 467px) {
  .download-img {
    width: 68px;
    margin-left: 10px;
    height: 14px;
  }
}

.android-icon-align {
  margin-top: 10px;
  width: 100px;
  object-fit: fill;
  height: 47px;
}

.ios-icon-align {
  margin-top: 17px;
}

.footer-text-center {
  margin-top: 30px;
  margin-left: 20px;
  text-align: left;
  font-size: 12px;
}

@media only screen and (max-width: 467px) {
  .footer-text-center {
    margin-top: 30px;
    width: 90px;
    margin-left: 14px;
    text-align:left;
    font-size: 10px;
  }
}


.android-image {
  max-width: 100px;
  object-fit: cover;
  height: 47px;
  border-radius: 10px;
}

@media only screen and (max-width: 467px) {
  .ios-image {
    width: 70px;
    object-fit: fill;
  }
}

.android-image {
  max-width: 110px;
  object-fit: fill;
  height: 47px;
  border-radius: 10px;
}

@media only screen and (max-width: 467px) {
  .android-image {
    max-width: 60px;
    height: 32px;
    object-fit: cover;
  }
}

.ios-icon-align {
  display: block;
  margin: 10 auto;
}

.android-icon-align {
  display: block;
  margin: 10px auto;
}

/* footer css stop  */
.inline-block {
  display: inline-block;
}

.email-error-message {
  background-color: white;
  color: red;
  width: 295px;
  height: 45px;
  border-radius: 5px;
  border: 2px solid red;
  margin-top: 10px;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
}

.birth-error-message {
  background-color: white;
  color: red;
  width: 295px;
  height: 65px;
  border-radius: 5px;
  border: 2px solid red;
  margin-top: 10px;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
}

.username-error-message {
  background-color: white;
  color: red;
  width: 295px;
  height: 65px;
  border-radius: 5px;
  border: 2px solid red;
  margin-top: 10px;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
}

.back-button {
  padding-left: 20px;
  background-color: transparent;
  border: none;
}

.btn-continue {
  margin-top: 40px;
  width: 295px;
  height: 45px;
  border: 2px solid #7c10a7 !important;
  background-color: #7c10a7 !important;
}

.button-continue:disabled {
  background-color: #b771d3;
}

.email-icon {
  position: relative;
  float: right;
  top: 33px;
  left: 196px;
}
.PrivacyContent_Modal_Screen__3a9iI {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}
.PrivacyContent_back_button__2zd6e {
  padding-top: 30px;
  padding-left: 20px;
}

.PrivacyContent_btn__3SNhr {
  background-color: white;
  border: none;
}

.PrivacyContent_text__2lYi3 h4 {
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.PrivacyContent_text__2lYi3 h5 {
  text-align: center;
  font-weight: 700;
}

.PrivacyContent_text__2lYi3 p {
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  justify-content: center;
  margin: auto;
  width: 80%;
}

.PrivacyContent_back_button__2zd6e {
  padding-top: 30px;
  padding-left: 20px;
}

.PrivacyContent_btn__3SNhr {
  background-color: transparent;
  border: none;
}

.PrivacyContent_text__2lYi3 h4 {
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.PrivacyContent_privacy__1e8hA {
  padding: 40px;

  border-radius: 5px;
  color: white;
  font-family: "Montserrat";
}
.PrivacyContent_heading__m0K5d {
  font-weight: 700;
  font-size: 1.5rem;
}

.PrivacyContent_bg__2kOMs {
  background: linear-gradient(
    254.59deg,
    #4cc9f0 -3.55%,
    #4895ef 14.74%,
    #560bad 44.39%,
    #b5179e 74.54%,
    #f72585 91.34%
  );
  height: 716px;
  overflow-y: scroll;

  overflow: "scroll";
}
.PrivacyContent_bg__2kOMs::-webkit-scrollbar {
  background-color: #e5c8f0;
  height: 397px;
  width: 10px;
  border-radius: 20px;
}

.PrivacyContent_bg__2kOMs::-webkit-scrollbar-thumb {
  background-color: #7c10a7;
  border-radius: 10px;
}
.PrivacyContent_paragraph__2HxJb {
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 400;
}

/* @import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Oswald&family=Roboto&display=swap'); */
.App {
    overflow: hidden;
}



