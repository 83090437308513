.sign_up {
  display: block;
  /* border: 1px solid rgba(189, 189, 189, 1); */
  height: 716px;
  width: auto;
}

@media screen and (max-width: 400px) {
  .sign_up {
    height: 816px;
  }
}

.back_button {
  margin: 20px;
}

.btn {
  background-color: white;
  border: none;
}

.sign_up_hint {
  position: relative;
  font-family: "Montserrat";
  top: 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
}

.sign_up_options {
  position: relative;
  top: 15%;
}

.union_svg {
  display: block;
  margin: auto;
}

.sign_up_hint_text {
  display: block;
  position: relative;
  top: 20px;
  width: 300px;
  margin: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #000000;
}

.sign_in {
  display: block;
  padding-top: 20px;
}

.sign_in .btn {
  display: block;
  border: 2px solid #7c10a7;
  height: 45px;
  width: 47%;
  margin: auto;
  border-radius: 5px;
  text-align: center;
  background: white;
  cursor: pointer;
  border-radius: 5px;
}

@media screen and (max-width: 550px) {
  .sign_in .btn {
    width: 50%;
  }
}

.sign_in .btn p {
  display: block;
  margin: auto;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #7c10a7;
}
