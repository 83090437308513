.pick_user_name_screen {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}

.back_button {
  padding-top: 30px;
  padding-left: 20px;
}

.btn {
  background-color: white;
  border: none;
}

.button_continue:disabled {
  background-color: #b771d3;
}

.user_name_icon {
  display: block;
  text-align: center;
  padding-top: 140px;
}

.title {
  display: block;
  margin: auto;
  text-align: center;
  padding-top: 20px;
  width: 43%;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}

.username {
  /* display: flex; */
  margin: auto;
  text-align: center;
  justify-content: center;
  height: 45px;
}

.user_name {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  width: 295px;
}

@media screen and (max-width: 470px) {
  .user_name {
    border: 1px solid;
    width: 70%;

  }
}

.input_field {
  padding-top: 30px;
}


.continue_button {
  text-align: center;
  position: relative;
  top: 30%;
}


.button_continue {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}

.button_continue p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}