.list_icons {
  padding-top: 50px;
  display: block;
}
.scrolling_box {
  display: block;
  margin: auto;
  width: 340px;
  height: 420px;
  overflow-y: scroll;
  
  text-align: center;
}


#style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #7c10a7;
}
.outer_selected_box {
  display: block;
  border-radius: 5px;
  height: 80px;
  width: 97%;
  margin: auto;
  border: 2px solid;
  border-image: linear-gradient(#4cc9f0, #4895ef, #560bad, #b5179e, #f72585) 27;
}

.outer_box:hover {
  border-radius: 5px;
  border: 2px solid ;
   border-image: linear-gradient(#4cc9f0, #4895ef, #560bad, #b5179e, #f72585) 27;
  cursor: pointer;
}
section {
  align-items: center;
  justify-content: center;
  height: 100%;
}
.icon_container {
  display: inline-flex;
}

.outer_box {
  display: block;
  cursor: pointer;
  border-radius: 5px;
  height: 80px;
  width: 70px;
  border: 1px solid #bdbdbd;
  margin: 3px;
}
.icons {
  display: block;
  margin: auto;
  padding-top: 20px;
  height: 20px;
  cursor: pointer;
  width: 36px;
  font-family: Fugaz One;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
}
.icon_title {
  display: block;
  margin: auto;
  cursor: pointer;
  padding-top: 30px;
  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  height: 12px;
  width: 70px;
}
