.message {
  padding-top: 20px;
  padding-left: 15px;
}

.error_message {
  border: 2px solid red;
  width: 295px;
  margin: auto;
  height: 45px;
  border-radius: 5px;
}

@media screen and (max-width: 470px) {
  .error_message {
    line-height: 10px;
    width: 74%;
  }
}

.name_error {
  margin: auto;
  color: red;
  width: 275px;
  line-height: 13px;

  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  padding-top: 6px;
}

@media screen and (max-width: 470px) {
  .name_error {
    line-height: 15px;
    width: 84%;
  }
}
