.modal {
  display: block;
  width: auto;
  height: 716px;
}

.modal_Text {
  /* background: linear-gradient(
    254.59deg,
    #4cc9f0 -3.55%,
    #4895ef 14.74%,
    #560bad 44.39%,
    #b5179e 74.54%,
    #f72585 91.34%
  ); */
  text-align: center;
  color: black;
  font-weight: 600;
  font-family: "Montserrat";
}

.time_background {
  padding: 4px;
  color: black;
  width: 210px;
  margin: auto;
}

.remain_time {
  font-size: 28px;
  color: #7c10a7;
  margin: auto;
}
