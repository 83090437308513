.privacy_policy_sub_title {
  display: flex;
  margin: auto;
}

.privacy_policy_content {
  padding-left: 49px;
  width: 296px;
  margin-top: -37px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  height: 45px;
}

.privacy-section {
  display: inline-block;
  margin-left: 39px;

}
@media only screen and (max-width: 500px) {
  .privacy-section {
    display: inline-block;
    margin-left: 65px;
  }
}
