.email_sign_up {
  display: block;
  padding-top: 90px;
}
.btn {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  font-size: 20px;

  border-radius: 5px;
  text-align: center;
  background: #7c10a7;
  font-family: "Montserrat";

  color: #ffffff;

  cursor: pointer;
  border-radius: 5px;
  border: none;
}

@media screen and (max-width: 400px) {
  .btn {
    width: 80%;

  }
}

