.google_sign_up {
  display: block;
  position: relative;
  top: 200%;
  border: 2px solid rgba(0, 0, 0, 1);
  margin: auto;
  width: 295px;
  height: 46px;
  border-radius: 5px;
}

@media screen and (max-width: 480px) {
  .google_sign_up {
    border: 1px solid;
    width: 80%;
  }
}


.google {
  display: block;
  margin: auto;
  width: 250px;
  height: 42px;
}

@media screen and (max-width: 2224px) {
  .google {
    width: 100%;
  }
}

.google_text {
  display: block;
  margin: auto;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  text-align: center;
}


.google_sign_up {
  display: flex;
  align-items: center;
}

.google_button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  width: 295px;
  margin-right: 0.9rem;
  border: 1px solid #000;
  border-radius: 0.25rem;
}

.google_icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.9rem;
}
