.question_result {
  display: block;
  width: auto;
  height: auto;
}
.scrollbar_result_screen {
  max-height: 716px;
  width: 102%;
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scrollbar_result_screen::-webkit-scrollbar {
  background-color: #e5c8f0;
  border-radius: 5px;
  width: 10px;
}

.scrollbar_result_screen::-webkit-scrollbar-thumb {
  background-color: #7c10a7;
  border-radius: 5px;
}


@media screen and (max-width: 425px) {
  .question_result {
    height: auto;
  }
}

.back_button {
  padding-top: 20px;
  padding-left: 20px;
}

.btn {
  background-color: white;
  border: none;
}

.image {
  padding-top: 20px;
  padding-left: 30px;
}
@media screen and (max-width: 625px) {
  .image {
    margin-left: 70px;
  }
}
@media screen and (max-width: 425px) {
  .image {
    margin-left: 0px;
  }
}

.comment {
  cursor: pointer;
  margin: 20px auto;
}
@media screen and (max-width: 925px) {
  .comment {
    margin-left: 170px;
  }
}
@media screen and (max-width: 425px) {
  .comment {
    margin-left: 110px;
  }
}
.img {
  border-radius: 5px;
  width: 166px;
  height: 191px;
  object-fit: cover;
}

.heading {
  padding-top: 25px;
}

.heading h3 {
  text-align: center;
  margin: auto;
  width: 85%;
  height: 100px;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
}

.outerBox {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  height: 100%;
}

@media screen and (max-width: 425px) {
  .outerBox {
    margin: 48px;
  }
}

.vote_options {
  padding-top: 35px;
  margin: auto;
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  width: 45%;
}

.answer {
  display: flex;
  margin: auto;
  justify-content: center;
  text-align: center;
  width: 45%;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
  padding-top: 30px;
  text-transform: uppercase;

}

.box {
  height: 10%;
}

.final_answer {
  display: flex;
  margin: auto;
  justify-content: center;
  text-align: center;
  width: 45%;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  margin-top: 30px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.circle {
  padding-bottom: 20px;
  float: right;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  position: relative;
}

.circle p {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  font-family: Montserrat;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 30px;
}

.circle::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
}

.circle::after {
  content: "";
  position: absolute;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  background-color: white;
  border-radius: 50%;
  z-index: -1;
}

.votes {
  width: 70%;
  padding-top: 20px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}

.decision {
  display: flex;
  margin: auto;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 800;
  line-height: 15px;
  height: 15px;
  width: 294px;
  text-transform: uppercase;
}

.box {
  border-bottom: 1px solid #e5e5e5;
  height: auto;
}

.endline {
  border-bottom: 1px solid grey;
  padding: 10px;
}