.sign_up {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}

.back_button {
  padding-top: 30px;
  padding-left: 20px;
}
.btn {
  background-color: white;
  border: none;
}
.sign_up_hint {
  display: block;
  margin: auto;
  padding-top: 5px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
}
.sign_up_options {
  display: block;
  margin: auto;
}
.union_svg {
  display: block;
  padding-top: 200px;
  margin: auto;
}
.sign_up_hint_text {
  display: block;
  width: 295;
  height: 55px;
  margin: auto;
  padding-top: 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}
.google{
  position: relative;
  top: -70px;

}
