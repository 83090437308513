#image6 img,
#image3 img {
  height: 162px;
}
div#image3 {
  display: block;
}
#image5 img {
  height: 165px;
}

#image2 img {
  height: 510px;
}

.enable-img-overlay:after {
  content: "";
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

.bgImg {
  max-width: 100%;
  width: 100%;
  display: inline-block;
  position: relative;
  height: 260px;
  object-fit: cover;
}

.img {
  flex: 1 1 calc(50% - 4px);
  position: relative;
  margin: 2px;
  overflow: hidden;
}

.img:first-child:nth-last-child(3) ~ .img:nth-child(1),
.img:first-child:nth-last-child(3) ~ .img:nth-child(2),
.img:first-child:nth-last-child(3) ~ .img:nth-child(3) {
  /* flex: 0 0 100%; */
  flex: 100%;
  height: 162px;
}

.img:first-child:nth-last-child(3) ~ .img:nth-child(1) img {
  height: 162px;
}

/* .img:first-child:nth-last-child(3) ~ .img:nth-child(1) {
  height: 162px;
} */

.paraGraph {
  margin-top: 0;
  margin-bottom: 0rem;
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 0;
  color: #fff;
  background: #00000080;
  padding: 20px;
}

.Clickimg {
  box-sizing: border-box;
  position: absolute;
  width: 60px;
  height: 60px;
  display: inline-block;
  top: 23%;
  left: 40%;
  transform: traslate(-50%, -50%);
  border-radius: 50px;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
  width: 100%;
}

@media screen and (max-width: 768px)  { 
  .Toastify__toast-theme--colored.Toastify__toast--success  {
    width: 100%;

    
  }
}

