.remain_time {
  font-size: 28px;
  color: #7c10a7;
  margin: auto;
}

.popUp_signUp_btn {
  background-color: #7c10a7;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  width: 300px;
  padding: 8px;
  margin: 30px 0px 10px 0px;
  font-family: "Montserrat";
}

@media (max-width: 768px) {
  .popUp_signUp_btn {
    width: 100%;
  }
}

.button_container {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}
.popUp_signUp_btn:hover {
  background-color: #7c10a7;
  border-color: #7c10a7;
}

.popUp_login_btn {
  border-radius: 5px;
  border: none;
  background-color: white;
  color: #7c10a7;
  border: 1px solid #7c10a7;
  font-size: 20px;
  font-family: "Montserrat";
  width: 300px;
  padding: 8px;
}
@media (max-width: 768px) {
  .popUp_login_btn {
    width: 100%;
  }
}

.download_btn {
  width: 100%;
}

@media (max-width: 768px) {
  .download_btn {
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.popUp_login_btn:hover {
  background-color: #7c10a7;
  color: white;
  border-color: #7c10a7;
}



.heading_logo{
  padding-top: 1px;
 text-align: center;
 justify-content: center;
 margin: auto;
 width: 100%;
}
.footer_title {
  font-family: "Montserrat";
  margin-top:20px;
  margin-bottom:0px;
  font-size: 14px;
  text-align: center;
  color: black;
  font-weight: 600;
}
@media (max-width: 768px) {
  .footer_title {
    margin: 20px auto;
  }
}

.download_btn{
  margin-top: 12px;
  justify-content: center;
  text-align: center;
}
.logoContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
}
.logo {
  width: 140px;
  /* height: 120px; */
}
.android_logo {
  width: 150px;
}
.ios_logo {
  width: 120px;
  height: auto;
}

@media (max-width: 768px) {
  .ios_logo {
    margin-left: 0px;
    height: auto;
  }
}
.dialogText {
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 10px;
  font-family: "Montserrat";
  color: black;
  font-weight: 600;
  text-align: center;
}

.subtitle {
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
}

.close_button:hover {
  background-color: #7c10a7;
  color: white;
  border-color: #7c10a7;
}

.close_button {
  border-radius: 5px;
  border: none;
  background-color: white;
  color: #7c10a7;
  border: 1px solid #7c10a7;
  font-size: 20px;
  font-family: "Montserrat";
  width: 300px;
  padding: 8px;
}
@media (max-width: 768px) {
  .close_button {
    width: 100%;
  }
}