
.back_button {
  padding-top: 30px;
  padding-left: 20px;

}

.back-btn {
  background-color: white;
  border: none;
}

.text h4 {
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.privacy{
 
  padding: 40px;

  border-radius: 5px;
  color: white;
  font-family: "Montserrat";
}
.heading{
  font-weight: 700;
  font-size: 1.5rem;

}
.bg{
  background:  linear-gradient(254.59deg, #4CC9F0 -3.55%, #4895EF 14.74%, #560BAD 44.39%, #B5179E 74.54%, #F72585 91.34%);
  height: 716px;
  overflow-y: scroll;

  overflow: "scroll";
}
.bg::-webkit-scrollbar {
  background-color: #e5c8f0;
  height: 397px;
  width: 10px;
  border-radius: 20px;
}

.bg::-webkit-scrollbar-thumb {
  background-color: #7c10a7;
  border-radius: 10px;
}

.paragraph{
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 400;
}