.Comment {
  display: block;
  /* border: 1px solid rgba(189, 189, 189, 1); */
  width: auto;
  height: auto;
  border-radius: 0px;
}
.back_button {
  position: relative;
  top: 29%;
  left: 35%;
  cursor: pointer;
}
.btn {
  background-color: white;
  border: none;
}
.button_continue:disabled {
  background-color: #b771d3;
}
.heading {
  width: 100%;
  padding: 25px;
}
@media only screen and (max-width: 467px) {
  .heading {
    width: 100%;
  padding: 25px;

  }
}
.outerBox {
  border-top: 1px solid #bdbdbd;
}
.bottom_outerBox {
  border-top: 1px solid #bdbdbd;
}
.total_comment {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}
.comment_field {
  display: flex;
  margin: 53px auto;
}
@media only screen and (max-width: 867px) {
  .comment_field {
    display: flex;
    margin: 30px;
  }
}
.input_placeholder {
  display: flex;
  width: 90%;
  height: 40px;
  border-radius: 5px;
  justify-content: center;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  border: 1px solid;

  left: 20px;
}
.post_btn {
  padding-top: 50px;
  text-align: center;
}
@media only screen and (max-width: 867px) {
  .post_btn {
    padding-top: 0px;
    margin-bottom: 20px;
  }
}
.btn_post:disabled {
  background-color: #b771d3;
}

.btn_post {
  color: #ffffff;
  background: #7c10a7;
  height: 45px;
  width: 83px;
  border: none;
  border-radius: 5px;
  padding-top: 10px;
}
.btn_post p {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.scrollbar {
  overflow-y: auto; /* or overflow-y: scroll */
  overflow-x: hidden;
  height: 390px; /* set the height of the div */
}

.force-overflow {
  min-height: 670px;
}

#wrapper {
  text-align: center;
  width: 500px;
  margin: auto;
}

#style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #7c10a7;
}
.alignment {
  border: "1px solid grey";
  height: 390px;
}
