.bgImg {
  max-width: 100%;
  width: 100%;
  display: inline-block;
  position: relative;
  height: 240px;
  object-fit: cover;
}

.img {
  flex: 1 1 calc(50% - 4px);
  position: relative;
  margin: 2px;
  overflow: hidden;
}


.paraGraph {
  margin-top: 0;
  margin-bottom: 0rem;
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 0;
  color: #fff;
  background: #00000080;
  padding: 20px;
} 

.Clickimg {
  box-sizing: border-box;
  position: absolute;
  width: 60px !important;
  height: 60px !important;
  top:20px;
  left: 0;
  right: 0;
  margin-left:auto;
  margin-right: auto;
  border-radius: 50px;
}
