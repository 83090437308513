.logout_btn {
  padding-top: 30px;
  cursor: pointer;
}
@media (min-width: 580px) {
    .logout_btn {
     padding-right: 20px;  
    }
}
.btn {
  border: none;
  background: transparent;
}
