.terms_and_condition_sub_title {
  display: flex;
  margin: auto;
}
.form_check {
  width: 40px;
  height: 35px;
  margin-top: 9px;
}
.terms_and_condition_content {
  padding-left: 49px;
  width: 296px;
  margin-top: -37px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  height: 45px;
}
@media only screen and (max-width: 800px) {
  .terms_and_condition_content {
    width: auto;
  }
}