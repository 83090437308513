.welcome_user_screen {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}
.back_button {
  padding-top: 30px;
  padding-left: 20px;
}
.btn {
  background-color: white;
  border: none;
}
.name {
  color: #7c10a7;
}
.user_icon {
  text-align: center;
  padding: 10px;
  padding-top: 25%;
}
.content {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  padding-bottom: 20px;
}
.certified_title {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
}
.continue_button {
  text-align: center;
  position: relative;
  top: 210px;


}
@media (min-width: 1400px) and (max-width: 2767px) {
  .continue_button {
    position: relative;
    top: 210px;
  }
}
@media screen and (max-width: 680px) {
  .continue_button {
    padding-top: 5%;
  }
}
.button_continue {
  display: block;
  height: 45px;
  padding-left: 15%;
  padding-right: 15%;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}
.button_continue p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}
