.bg-custom-primary {
    background-color: #7c10a7 !important;
  }
  
  .border-custom-primary {
    border-color: #7c10a7;
  }
  
  .bg-custom-white {
    background-color: white;
  }