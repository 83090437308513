@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.radialProgressBar {
  top: 45%;
  left: 42%;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: block;
  background: #ddd;
  margin: 20px;
  position: relative;
  /* background-image: linear-gradient( 30.59deg,  #3f5efb 20.54%,  transparent 50%),
    linear-gradient(18deg, #fc46f2 10.39%, #e6e6e6 50%),
    linear-gradient(24.59deg,#4cc9f0 23.55%, #e6e6e6 50%); */

  background-image: linear-gradient(
    34.59deg,
    #4cc9f0 12.55%,
    #4895ef 12.74%,
    #560bad 30.39%,
    #b5179e 46.54%,
    #f72585 52.34%,
    transparent -1%
  );
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  transition: all 0.5s ease-in;
}
@media screen and (max-width: 750px) {
  .radialProgressBar {
    top: 45%;
    left: 38%;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: block;
    background: #ddd;
    margin: 20px;
    position: relative;
    /* background-image: linear-gradient( 30.59deg,  #3f5efb 20.54%,  transparent 50%),
      linear-gradient(18deg, #fc46f2 10.39%, #e6e6e6 50%),
      linear-gradient(24.59deg,#4cc9f0 23.55%, #e6e6e6 50%); */
  
    background-image: linear-gradient(
      34.59deg,
      #4cc9f0 12.55%,
      #4895ef 12.74%,
      #560bad 30.39%,
      #b5179e 46.54%,
      #f72585 52.34%,
      transparent -1%
    );
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition: all 0.5s ease-in;
  }
}

@media screen and (max-width: 450px) {
  .radialProgressBar {
    top: 45%;
    left: 34%;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: block;
    background: #ddd;
    margin: 20px;
    position: relative;
    /* background-image: linear-gradient( 30.59deg,  #3f5efb 20.54%,  transparent 50%),
      linear-gradient(18deg, #fc46f2 10.39%, #e6e6e6 50%),
      linear-gradient(24.59deg,#4cc9f0 23.55%, #e6e6e6 50%); */
  
    background-image: linear-gradient(
      34.59deg,
      #4cc9f0 12.55%,
      #4895ef 12.74%,
      #560bad 30.39%,
      #b5179e 46.54%,
      #f72585 52.34%,
      transparent -1%
    );
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition: all 0.5s ease-in;
  }
}
.radialProgressBar .overlay {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: #fff;
  position: absolute;
  top: 10%;
  left: 10%;
}
.load_spinner {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  width: auto;
  height: 716px;
  border-radius: 0px;
}
.spinner_text {
  position: relative;
  top: 45%;
  color: purple;
  margin: auto;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;

  text-align: center;
}
