.Password {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}
.back_button {
  padding-top: 30px;
  padding-left: 20px;
}
.btn {
  background-color: white;
  border: none;
}
.SignUpSection {
  padding-top: 25px;
}
.PasswordSvg {
  display: block;
  margin: auto;
  width: 78px;
  height: 76px;
}
.SignUpSection p {
  display: block;
  padding-top: 15px;
  width: 295px;
  height: 58px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: hsl(0, 0%, 0%);
  margin: auto;
}
@media screen and (max-width: 480px) {
  .SignUpSection p {
    width: auto;
  }
}
.InputField_input_placeholder {
  padding-top: 10px;
}
.InputField_confirm_password {
  padding-top: 50px;
}
.input_placeholder {
  display: block;
  height: 45px;
  width: 295PX;
  margin: auto;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 0 40px 0 40px;
}
@media screen and (max-width: 480px) {
  .input_placeholder {
    border: 1px solid;
    width: 80%;
  }
}
.heading {
  display: flex;
  padding-top: 20px;
  height: 58px;
  width: 37%;
  margin: auto;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .heading {
    width: auto;
    justify-content: center;
  }
}
.confirm_password {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 0 40px 0 40px;

}
@media screen and (max-width: 480px) {
  .confirm_password {
    border: 1px solid;
    width: 80%;
  }
}
.continue_button {
  text-align: center;
  padding-top: 5%;
}

.button_continue {
  display: block;
  height: 45px;
  width: 295px;

  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 450px) {
  .button_continue  {
  width: 80%;

  }
}
.button_continue p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}

.button_continue:disabled {
  background-color: #b771d3;
}
.hide_show_password {
  position: relative;
  width: 3%;
  text-align: center;
  left: 65%;
  bottom: 33px;
}
@media screen and (max-width: 450px) {
  .hide_show_password  {
    left: 73%;

  }
}