@media screen and (min-width: 480px) {
  .login {
    display: block;
    height: 10px;
    border: 1px solid rgba(189, 189, 189, 1);
    border-radius: 0px;
  }
}

.login {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}
.back_button {
  padding-top: 30px;
  padding-left: 20px;
}
.btn {
  background-color: white;
  border: none;
}
.button_continue:disabled {
  background-color: #b771d3;
}
.login_section {
  padding-top: 110px;
}
.Email {
  display: block;
  margin: auto;
  width: 76px;
  height: 80px;
}
.login_section p {
  display: block;
  margin: auto;
  width: 295px;
  height: 58px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}
@media screen and (max-width: 350px) {
  .login_section p {
    width: 100%;
  }
}
.InputField {
  display: block;
  padding-top: 25px;
}

.mail_field {
  display: block;
  height: 45px;
  width: 295px;
  border: 1px solid #ced4da;
  margin: auto;
  border-radius: 5px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 0 20px 0 20px;
}
.mail_field:focus {
  outline: none;
  border-color: #ced4da;
}

@media screen and (max-width: 350px) {
  .mail_field {
    width: 70%;
  }
}
.password_field {
  display: block;
  height: 45px;
  width: 295px;
  border: 1px solid #ced4da;
  margin: auto;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;

  line-height: 20px;
  text-align: center;
  padding: 0 20px 0 20px;
  margin: 20px auto;
}
.password_field:focus {
  outline: none;
  border-color: #ced4da;
}
@media screen and (max-width: 350px) {
  .password_field {
    width: 70%;
  }
}

.continue_button {
  text-align: center;
  padding-top: 25%;
}
.button_continue {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 350px) {
  .button_continue {
    width: 70%;
  }
}
.button_continue p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}
