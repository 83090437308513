.cateogory_screen {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}
.back_button {
  padding-top: 30px;
  padding-left: 20px;
}
.btn {
  background-color: white;
  border: none;
}

.cateogory_screen_content {
  display: block;
  height: 29px;
  width: 295px;
  margin: auto;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
}

.cateogory_screen_title {
  display: block;
  height: 29px;
  width: 295px;
  margin: auto;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
  padding-top: 34px;
}

.next_button {
  text-align: center;
  padding-top: 8%;
  padding-left: 3%;
}
.next_continue {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}
.next_continue p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}
