.scrollbar {
  height: 450px;
  overflow: "scroll";
  max-width: 530px;
  border-radius: 5px;
  overflow-y: scroll;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #e5c8f0;
  height: 397px;
  width: 10px;
  border-radius: 20px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #7c10a7;
  border-radius: 10px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-10 {
  margin-top: 10px;
}

.btn-border {
  color: #7c10a7;
  border: 2px solid #7c10a7;
  width: 295px;
  height: 45px;
  border-radius: 5px;
  margin-top: 10px;
}

.btn-text {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.btn-text:hover {
  background-color: white;
  color: #7c10a7;
  border: 2px solid #7c10a7;
}

.w-all {
  width: 295px;
}

.m-auto {
  margin: auto;
  width: 295px;
}

.form-width {
  width: 295px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #767676;
  outline: none;
  box-shadow: none !important;
  text-align: center;
}

.form-width:focus {
  border: 1px solid #767676;
}

.text-color {
  color: black;
}

.show-password-icon {
  position: relative;
  top: 32px;
  left: 165px;
}

.username-valid-icon {
  position: relative;
  top: 34px;
  left: 118px;
}

.show-confirm-password-icon {
  position: relative;
  top: 32px;
  left: 103px;
}

.agevalid-icon {
  position: relative;
  top: 34px;
  left: 166px;
}

.text-bold {
  font-weight: 700;
}

.montserrat-font {
  font-family: "Montserrat";
}

.label-text {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 700;
  text-align: left;
}

.link-termscondition {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  color: #7c10a7;
}

.info-text-color {
  font-family: "Montserrat";
  font-size: 12px;
  text-align: left;
  font-weight: 300;
  font-style: italic;
  color: black;
}

.placeholder-text {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #767676;
}
.custom-highlight:focus 
{
  outline:none;
  border-color: #7c10a7 !important;
}


.big-checkbox input[type="checkbox"] {
  width: 32px;
  height: 32px;
  box-shadow: none;
  border: 1px solid grey;
}

.big-checkbox label {
  display: block;
  margin-left: 20px;
}

.color-checkbox label {
  display: block;
  box-shadow: none;

  margin-left: 20px;
}

.checkbox-text {
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
}

.color-checkbox input[type="checkbox"] {
  width: 32px;
  height: 32px;
  background-color: #7c10a7;
  box-shadow: none;
  border: none;
}

.btn-enable {
  /* margin-top: 40px; */
  width: 295px;
  height: 45px;
  border: 2px solid #7c10a7 !important;
  background-color: #7c10a7 !important;
}

/* footer css start  */
.bg-linear-gradient {
  height: 100px;
  max-width: 575px;
  background: linear-gradient(254.59deg,
      #4cc9f0 -3.55%,
      #4895ef 14.74%,
      #560bad 44.39%,
      #b5179e 74.54%,
      #f72585 91.34%);
  margin: 20px auto;
}

.linear-gradient-bg {
  background: linear-gradient(254.59deg,
      #4cc9f0 -3.55%,
      #4895ef 14.74%,
      #560bad 44.39%,
      #b5179e 74.54%,
      #f72585 91.34%);
}

.footer-text {
  height: 28px;
  width: 141px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 700;
  color: white;
  text-align: end;
}

.download-text {
  width: 183px;
  font-family: "Montserrat";
  color: white;
  font-size: 19px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  padding: 25px;
}

@media only screen and (max-width: 467px) {
  .download-text {
    text-align: left;
    width: 110px;
    font-size: 18px;
  }
}
.download-links{
margin-left: 20px;
}

.download-img {
  width: 88px;
  height: 17px;
  margin-top: 10px;
  border-radius: 0px;
}

@media only screen and (max-width: 467px) {
  .download-img {
    width: 68px;
    margin-left: 10px;
    height: 14px;
  }
}

.android-icon-align {
  margin-top: 10px;
  width: 100px;
  object-fit: fill;
  height: 47px;
}

.ios-icon-align {
  margin-top: 17px;
}

.footer-text-center {
  margin-top: 30px;
  margin-left: 20px;
  text-align: left;
  font-size: 12px;
}

@media only screen and (max-width: 467px) {
  .footer-text-center {
    margin-top: 30px;
    width: 90px;
    margin-left: 14px;
    text-align:left;
    font-size: 10px;
  }
}


.android-image {
  max-width: 100px;
  object-fit: cover;
  height: 47px;
  border-radius: 10px;
}

@media only screen and (max-width: 467px) {
  .ios-image {
    width: 70px;
    object-fit: fill;
  }
}

.android-image {
  max-width: 110px;
  object-fit: fill;
  height: 47px;
  border-radius: 10px;
}

@media only screen and (max-width: 467px) {
  .android-image {
    max-width: 60px;
    height: 32px;
    object-fit: cover;
  }
}

.ios-icon-align {
  display: block;
  margin: 10 auto;
}

.android-icon-align {
  display: block;
  margin: 10px auto;
}

/* footer css stop  */
.inline-block {
  display: inline-block;
}

.email-error-message {
  background-color: white;
  color: red;
  width: 295px;
  height: 45px;
  border-radius: 5px;
  border: 2px solid red;
  margin-top: 10px;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
}

.birth-error-message {
  background-color: white;
  color: red;
  width: 295px;
  height: 65px;
  border-radius: 5px;
  border: 2px solid red;
  margin-top: 10px;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
}

.username-error-message {
  background-color: white;
  color: red;
  width: 295px;
  height: 65px;
  border-radius: 5px;
  border: 2px solid red;
  margin-top: 10px;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
}

.back-button {
  padding-left: 20px;
  background-color: transparent;
  border: none;
}

.btn-continue {
  margin-top: 40px;
  width: 295px;
  height: 45px;
  border: 2px solid #7c10a7 !important;
  background-color: #7c10a7 !important;
}

.button-continue:disabled {
  background-color: #b771d3;
}

.email-icon {
  position: relative;
  float: right;
  top: 33px;
  left: 196px;
}