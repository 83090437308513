.question_heading_content {
  display: block;
  font-family: "Montserrat";
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  margin: 10px;
}

.comment_and_vote {
  margin: 10px;
}

.comment {
  cursor: pointer;
}

.count {
  margin: 7px;
}

.count_section {
  display: flex;
  /* width: 15%; */
  justify-content: center;
}

@media screen and (min-device-width: 768px) and (max-device-width: 1200px) {
  .count_section {
    width: 20%;
  }
}

@media screen and (max-width: 400px) {
  .count_section {
    width: 20%;
  }
}

.comments {
  text-align: center;
  cursor: pointer;
}

.votes {
  text-align: center;
  cursor: pointer;
}

.vote {
  cursor: pointer;
}

.read_or_hide {
  cursor: pointer;
}

.text {
  position: relative;
  display: block;
  margin: 5px;
  font-family: "Montserrat";
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
}

.heading {
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  width: 98%;
}

.questionCategory {
  margin: 10px;
  margin-top: -7px;
  margin-bottom: -4px;
}
