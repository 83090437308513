.button_continue:disabled {
  background-color: #b771d3;
}

.privacy_policy_screen {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}

.back_button {
  padding-top: 30px;
  padding-left: 20px;
}

.btn {
  background-color: white;
  border: none;
}

.button_continue:disabled {
  background-color: #b771d3;
}

.privacy_policy_icon {
  display: block;
  margin: auto;
  text-align: center;
  padding-top: 40px;
}

.title {
  display: block;
  margin: auto;
  height: 58px;
  width: 49%;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  padding-top: 15px;
}

@media screen and (max-width: 680px) {
  .title {
    width: auto;
  }
}

.contents {
  display: block;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  margin: auto;
  height: 124px;
  width: 292px;
  padding-top: 1%;
}

@media screen and (max-width: 680px) {
  .contents {
    width: auto;
  }
}

.privacy_policy_btn {
  display: block;
  margin: auto;
  padding-top: 250px;
}

@media screen and (max-width: 1080px) {
  .privacy_policy_btn {
    padding-top: 250px;
    padding-left: 0px;
  }
}

.privacy_policy_button {
  display: block;
  margin: auto;
  height: 45px;
  width: 292px;
  border-radius: 5px;
  border: 2px solid rgba(124, 16, 167, 1);
  background: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 680px) {
  .privacy_policy_button {
    margin: auto;
  }
}

.privacy_policy_button_title {
  margin: auto;
  font-family: "Montserrat";
  width: 295px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #7c10a7;
}

@media screen and (max-width: 880px) {
  .privacy_policy_button_title {
    width: auto;
  }
}

.continue_button {
  text-align: center;
  position: relative;
  top: 18%;
}

@media screen and (max-width: 680px) {
  .continue_button {
    padding-top: 5%;
  }
}

.button_continue {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 380px) {

  .button_continue {
    width: 70%;
  }

}

.button_continue p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}