.check_box_icon {
  margin-left: -50px;
  height: 20px;
  width: 5%;
}

.user_exist_error {
  margin-left: -50px;
}

@media screen and (max-width: 470px) {
  .check_box_icon {
    margin-left: -20px;
  }
}

.message {
  padding-top: 20px;
  padding-left: 15px;
}

.error_message {
  border: 2px solid red;
  width: 294px;
  margin: auto;
  height: 45px;
  border-radius: 5px;
}

@media screen and (max-width: 470px) {
  .error_message {
    line-height: 10px;
    width: 54%;
  }
}

.name_error {
  margin: auto;
  color: red;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  padding-top: 10px;
}
