.heading h4 {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  justify-content: center;
}

.total_comment {
  display: block;
  text-align: center;
  margin: 30px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
}

.comment_section {
  margin: auto 7px;
}
@media only screen and (max-width: 467px) {
  .comment_section {
    margin: auto 25px;
  }
}
.usericon {
  text-align: center;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background: #c4c4c4;
}

.user_image {
  width: 40px;
  height: 40px;
  border-radius: 37px;
  object-fit: cover;
}

.username {
  margin: auto;
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 700;
  line-height: auto;
}

.login_username {
  margin: auto;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  line-height: auto;
}

.comment {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 400;
  line-height: auto;
  /* margin: auto; */

  color: black;
}

.list {
  margin: 5px auto;
  display: flex;
  list-style-type: none;

}

.days {
  width: 33px;
  font-family: "Montserrat";
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  color: #A6A6A6;
}

.like {
  width: 53px;
  font-family: "Montserrat";
  font-size: 11px;
  color: #A6A6A6;
  padding-left: 4px;
  font-weight: 600;
  line-height: 15px;
}

.likeimg {
  cursor: pointer;
}

.reply {
  width: 63px;
  font-family: "Montserrat";
  font-size: 11px;
  color: #A6A6A6;
  font-weight: 600;
  line-height: 15px;
  cursor: pointer;
}

h4:before,
h4:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #7c10a7;
  margin: auto;
}

.btn {
  border: none;
  background-color: white;
}

.postOwner {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #7c10a7;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.postOwner span {
  font-family: Montserrat;
  line-height: 0.1em;

  font-size: 12px;
  font-weight: 900;
  background: #fff;
  color: #7c10a7;
  padding: 0 10px;
}