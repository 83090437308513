.password_hint_list p {
  display: contents;
  font-weight: 600;
  font-size: 13px;
  font-family: "Montserrat";
  color: #7c10a7;
}
.password_hint_lis{
  padding: 3px;
}
.password_disable_hint_list p {
  display: contents;
  font-family: "Montserrat";
  font-size: 13px;
  color: #bdbdbd;
}
.ppp{
  padding: 10px
}

.disablecheckboxSvg{
  position: relative;
  right: 4px;
}
.validationBox {
  margin-top: 5px
}
@media screen and (max-width: 800px) {
  .validationBox {
  }
}