.modal {
    display: block;
    width: auto;
    height: 716px;
}

.modal_Text {
    max-width: 420px;
    margin: auto;
    text-align: center;
    font-size: 20px;
    color: black;
    font-weight: 600;
    font-family: "Montserrat";
}