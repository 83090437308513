.question_screen {
  display: block;
  width: auto;
  height: 716px;
  /* border: 1px solid #bdbdbd; */
}

@media screen and (max-width: 668px) {
  .question_screen {
    height: 100%;
  }
}

.question_heading {
  margin: 15px;
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  /* line-height: 29px; */
}

.image_grid_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px;
}

.Logout {
  position: relative;
  /* top: -15px; */
}

.post_remain_timer {
  display: flex;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  margin-top: 20px;
  max-width: 190px;
}



@media screen and (max-width: 468px) {
  .post_remain_timer {
    display: flex;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    margin-top: 20px;
    max-width: 190px;
    margin-left:-20px
  }
}

.live_timer {
  padding-left: 5px;
  padding-top: 5px;
  
}

@media screen and (max-width: 468px) {
  .live_timer {
   width: 0px;
   padding-left: 0px;
  }
}
.scrollbar_question_screen {
  max-height: 716px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 !important;
  margin:auto
}

@media only screen and (max-width: 767px) {
  .width-web {
    max-height: 716px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 !important;
  }
}


.scrollbar_question_screen::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollbar_question_screen::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar_question_screen::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #7c10a7;
}