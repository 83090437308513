.signUp_terms_and_condition {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}

.back_button {
  padding-top: 30px;
  padding-left: 20px;
}

.btn {
  background-color: white;
  border: none;
}

.Condition_Notes {
  display: block;
  margin: auto;
  text-align: center;
  padding-top: 100px;
}

.title {
  display: block;
  margin: auto;
  height: 58px;
  width: 295px;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  padding-top: 20px;
}

.terms_and_conditions_btn {
  display: block;
  margin: auto;
  padding-top: 150px;
  padding-left: 20px;
}

.terms_and_condition_button {
  display: block;
  margin: auto;
  height: 45px;
  width: 295px;
  border-radius: 5px;
  border: 2px solid rgba(124, 16, 167, 1);
  background: #ffffff;
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .terms_and_condition_button {
    width: 40%;
  }
}

.terms_and_condition_button p{
  margin: auto;
  font-family: "Montserrat", sans-serif;
  width: auto;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #7c10a7;
  border: none;
  background-color: white;
}

@media only screen and (max-width: 1200px) {
  .terms_and_condition_button p{
    font-size: 16px;
    line-height: 12px;
  }
}

.continue_button {
  text-align: center;
  position: relative;
  top: 27%;
  left: 13px;
}

.button_continue {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  background: #7c10a7;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 390px) {
  .button_continue {
    width: 80%;
  }
}

.button_continue p {
  display: block;
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: aliceblue;
  padding-top: 9px;
}

.button_continue:disabled {
  background-color: #b771d3;
}