.question_heading {
  margin: 15px;
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  width: 90%;
}

.question_img {
  height: 480px;
  margin: auto;
  background-repeat: no-repeat;
}

.multiple_type_question_image {
  height: 480px;
  background: linear-gradient(
    254.59deg,
    #4cc9f0 -3.55%,
    #4895ef 14.74%,
    #560bad 44.39%,
    #b5179e 74.54%,
    #f72585 91.34%
  );

  margin: auto;
}

@media screen and (max-width: 1024px) {
  .question_img {
    background-position: center;
  }
}

.question {
  background-color: transparent;
  margin: auto;
  position: relative;
  top: 28%;
}

@media screen and (max-width: 624px) {
  .question {
    width: auto;
  }
}

.button {
  width: 295px;
  display: flex;
  margin: 10px auto;
  border-radius: 25px;
  border: 2px solid black;
  background: white;
}
@media screen and (max-width: 424px) {
  .button {
    width: 75%;
  }
}

.multiple_choice_alignment {
  margin: 3px;
  padding: 2px;
  width: 90%;
  text-align: left;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
}

.button:hover {
  border: 2px solid skyblue;
}

.active_option_span {
  border: 2px solid black;
  background-color: white;
  color: #7c10a7;
}

.active_option {
  color: white;
  background: linear-gradient(
    254.59deg,
    #4cc9f0 -3.55%,
    #4895ef 14.74%,
    #560bad 44.39%,
    #b5179e 74.54%,
    #f72585 91.34%
  );
}

.sno {
  margin: auto;
  width: 21px;
  border-radius: 49px;
  border: 2px solid;
  padding: 0px 4px 0px 4px;
}

.index {
  font-size: 11px;
  font-weight: 700;
}

.yes_no_question {
  border-top: 11px solid white;
  border-bottom: 10px solid white;
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
  top: 43%;
  height: 70px;
  width: 34%;
}

.yes_button {
  font-size: 28px;
  width: 131px;
  padding: 0px;
  height: 70px;
  background-color: white;
  border: none;
  margin: 1px;
  margin-top: -11px;
}

.yes_or_no_text {
  width: auto;
  padding-top: 13px;
  font-family: "Montserrat";
  text-transform: uppercase;

  font-size: 30px;
  font-weight: 700;
  margin-top: -11px;
}

.image_grid_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px;
}

.img:after {
  content: "";
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

video {
  width: 100%;
  height: 499px;
  object-fit: cover;
}

.content {
  position: absolute;
  width: 750px;
  height: 480px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.yes_no_video_question {
  border-top: 11px solid white;
  border-bottom: 10px solid white;
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
  top: 12%;
  height: 70px;
  width: 34%;
}
