.Modal_Screen {
  display: block;
  border: 1px solid rgba(189, 189, 189, 1);
  height: 716px;
  border-radius: 0px;
}
.back_button {
  padding-top: 30px;
  padding-left: 20px;
}

.btn {
  background-color: white;
  border: none;
}

.text h4 {
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.text h5 {
  text-align: center;
  font-weight: 700;
}

.text p {
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  justify-content: center;
  margin: auto;
  width: 80%;
}

.back_button {
  padding-top: 30px;
  padding-left: 20px;
}

.btn {
  background-color: transparent;
  border: none;
}

.text h4 {
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.privacy {
  padding: 40px;

  border-radius: 5px;
  color: white;
  font-family: "Montserrat";
}
.heading {
  font-weight: 700;
  font-size: 1.5rem;
}

.bg {
  background: linear-gradient(
    254.59deg,
    #4cc9f0 -3.55%,
    #4895ef 14.74%,
    #560bad 44.39%,
    #b5179e 74.54%,
    #f72585 91.34%
  );
  height: 716px;
  overflow-y: scroll;

  overflow: "scroll";
}
.bg::-webkit-scrollbar {
  background-color: #e5c8f0;
  height: 397px;
  width: 10px;
  border-radius: 20px;
}

.bg::-webkit-scrollbar-thumb {
  background-color: #7c10a7;
  border-radius: 10px;
}
.paragraph {
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 400;
}
