.email_sign_up {
  position: relative;
  top: 150%;
}

.btn {
  display: block;
  height: 45px;
  width: 295px;
  margin: auto;
  border-radius: 5px;
  text-align: center;
  background: #7c10a7;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}

@media screen and (max-width: 480px) {
  .btn {
    width: 80%;
  }
}

.btn p {
  display: block;
  margin: auto;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}